import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Flex, Layout, Row, theme } from "antd";
import State from "../../context";
import AdminHeader from "./components/AdminHeader";
import AdvisorHeader from "./components/AdvisorHeader";
import MenuManagerProfile from "../../molecules/MenuManagerProfile";
import MenuSidebar from "../../molecules/MenuSidebar";
import ModalAddProspect from "../../organisms/ModalAddProspect";
import ModalAdminSettings from "../../organisms/ModalAdminSettings";
import ModalAdvisorArtBoard from "../../organisms/ModalAdvisorArtBoard";
import SectionAskAdvice from "../../organisms/SectionAskAdvice";
import ModalManagedUserData from "../../organisms/ModalManagedUserData";
import ModalProposalGuideManager from "../../organisms/ModalProposalGuideManager";
import PennyChatToggler from "../../organisms/SectionAskAdvice/components/PennyChatToggler";

import StyledLayout from "./styles";

import {
  isOrganizationLevel,
  isPortalAdmin,
} from "../../utils/helpers/specialized";

const { Content, Sider } = Layout;

const LayoutManager = ({ routes, children }) => {
  const location = useLocation();
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);

  return (
    <StyledLayout hasSider sidebarbackground={token.sidebarBackground}>
      <Sider breakpoint="md" collapsedWidth="0" width={184}>
        <Flex
          justify={"space-between"}
          style={{
            width: "100%",
            height: "100%",
            padding: "56px 0 56px 28px",
          }}
          vertical
        >
          <MenuSidebar routes={routes} />
          <MenuManagerProfile />
        </Flex>
      </Sider>
      <Layout
        style={{
          backgroundColor: location.pathname.includes(
            "advisor-portal/dashboard"
          )
            ? "#fafafa"
            : "#FFFFFF",
          minWidth: 800,
          overflow: "auto",
          width: "auto",
        }}
      >
        {isPortalAdmin() ? (
          <AdminHeader
            organization={state.organization?.name ?? state.orgName}
          />
        ) : (
          <AdvisorHeader
            organization={state.organization?.name ?? state.orgName}
          />
        )}
        <Row
          justify={"center"}
          style={{
            height: "100%",
          }}
        >
          <Content
            style={{
              maxWidth: 1550,
              overflow: "auto",
              padding: "28px 100px 50px 47px",
            }}
          >
            {children}
          </Content>
        </Row>
      </Layout>
      {isOrganizationLevel(state.organization?.name ?? state.orgName) && (
        <PennyChatToggler
          handleClick={() => state.openModal("openModalAskAdvice")}
        />
      )}

      <ModalAddProspect
        handleClose={() => state.closeModal("addProspectModal")}
        open={state.addProspectModal}
      />
      <ModalAdminSettings />
      <ModalAdvisorArtBoard />
      <SectionAskAdvice setState={setState} state={state} />
      <ModalManagedUserData
        handleClose={() => state.setKeyValue("openModalAddManagedUser", false)}
        open={state.openModalAddManagedUser}
      />
      <ModalProposalGuideManager
        handleClose={() => state.setKeyValue("openModalProposalGuide", false)}
        open={state.openModalProposalGuide}
      />
    </StyledLayout>
  );
};

export default LayoutManager;
