import { useContext, useEffect, useState } from "react";
import State from "../../../../../../context/admin/State";
import { Button, Flex, Input, Table } from "antd";
import MenuActions from "../../../../../../../../atoms/MenuActions";

import { getCurrencyValueWithRoundSign } from "../../../../../../../../utils/helpers/general";

import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as CirclePlusGreen } from "../../../../../../images/circle_plus_green.svg";

const getColumns = ({ handleDelete, handleUpdate, searchedText }) => [
  {
    dataIndex: "name",
    filteredValue: [searchedText],
    key: "name",
    onFilter: (value, record) =>
      String(record.name).toLowerCase().includes(value.toLowerCase()) ||
      String(record.category).toLowerCase().includes(value.toLowerCase()) ||
      String(record.location).toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.name.localeCompare(b.name),
    title: "Company Name",
  },
  {
    dataIndex: "url",
    key: "url",
    render: value => (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 300,
          whiteSpace: "nowrap",
        }}
      >
        <a
          href={value?.includes("https://") ? value : "https://" + value}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <u>{value}</u>
        </a>
      </div>
    ),
    sorter: (a, b) => a.url.localeCompare(b.url),
    title: "URL",
  },
  {
    title: "AUM (Quarterly)",
    dataIndex: "aumQuarterly",
    key: "aumQuarterly",
    render: value => getCurrencyValueWithRoundSign(value),
    sorter: (a, b) => (a.aumQuarterly ?? 0) - (b.aumQuarterly ?? 0),
  },
  {
    title: "AUM (Annually)",
    dataIndex: "aumAnnually",
    key: "aumAnnually",
    render: value => getCurrencyValueWithRoundSign(value),
    sorter: (a, b) => (a.aumAnnually ?? 0) - (b.aumAnnually ?? 0),
  },
  {
    className: "actions-menu-table-cell",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          handleDelete,
          companyId: restValues._id,
          handleUpdate,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({ handleDelete, companyId, handleUpdate }) => [
  {
    key: "1",
    label: <div onClick={() => handleUpdate(companyId)}>Edit Company</div>,
  },
  {
    key: "2",
    label: <div onClick={() => handleDelete(companyId)}>Delete Company</div>,
  },
];

const TabCompanies = () => {
  const [state] = useContext(State);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    setLoading(true);
    state.reFetch.companies().then(() => setLoading(false));
  }, []);

  const handleDelete = companyId => {
    state.setKeyValue("activeCompanyId", companyId);
    state.openModal("deleteCompanyModal");
  };

  const handleUpdate = companyId => {
    state.setKeyValue("activeCompanyId", companyId);
    state.openModal("companyModal");
  };

  return (
    <Flex vertical gap={20}>
      <Flex justify={"space-between"}>
        <Input
          className={"pointer"}
          onChange={event => setSearchedText(event.target.value)}
          placeholder="Search..."
          size="large"
          style={{ width: 236, borderRadius: 23 }}
          suffix={<SearchOutlined style={{ fontSize: 20, color: "#B1B1B1" }} />}
        />
        <Button
          shape={"round"}
          size={"small"}
          style={{ color: "#2E9642" }}
          type={"text"}
          onClick={() => state.openModal("companyModal")}
        >
          <Flex align={"center"} gap={8}>
            <CirclePlusGreen />
            <span style={{ fontFamily: "Poppins, sans-serif" }}>
              Add Company
            </span>
          </Flex>
        </Button>
      </Flex>
      <Table
        columns={getColumns({
          handleDelete,
          handleUpdate,
          searchedText,
        })}
        dataSource={state.companies
          ?.sort((a, b) => b.aumAnnually - a.aumAnnually)
          ?.map((it, index) => ({
            ...it,
            key: index,
          }))}
        loading={loading}
        pagination={{
          position: ["none", "bottomCenter"],
        }}
        size={"small"}
      />
    </Flex>
  );
};

export default TabCompanies;
