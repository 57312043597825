import { useContext } from "react";
import ReactPlayer from "react-player";
import State from "../../context";
import { Space, theme, Typography } from "antd";
import ModalQuarterlyGoals from "../../organisms/ModalQuarterlyGoals";
import SectionAdvisorDashboardAnalytics from "../../organisms/SectionAdvisorDashboardAnalytics";
import CardSkeleton from "../../atoms/CardSkeleton";

import StylesContainer from "./styles";

import { openFile, updateQuarterlyGoals } from "../../utils/requests/manager";

import { ReactComponent as File } from "../../icons/file.svg";

const { Title, Paragraph } = Typography;

const PageAdvisorDashboard = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const handleUpdateQuarterlyGoals = requestBody => {
    state.setKeyValue("loading", true);

    updateQuarterlyGoals(requestBody)
      .then(() => {
        state.closeModal("quarterlyGoalsModal");
        state.showSuccess("Quarterly Goals updated.");
        state.setAdminData();
      })
      .catch(console.log);
  };

  const getFeaturedMaterials = () =>
    state.marketingMaterialFolders
      ?.map(it => it.marketingMaterials)
      ?.flat()
      ?.filter(
        it => it?.featureContentOrder && it?.featureContentStatus === "Visible"
      )
      ?.sort((a, b) => a?.featureContentOrder - b?.featureContentOrder);

  return (
    <StylesContainer>
      <SectionAdvisorDashboardAnalytics />
      <Space
        direction="vertical"
        size={23}
        style={{
          width: 364,
          height: "fit-content",
          background: token.colorGrey3,
          borderRadius: 8,
          padding: 22,
        }}
      >
        <Space direction="vertical" size={13}>
          <Title
            style={{
              fontSize: 21,
              fontWeight: 300,
              color: token.colorLabel,
              margin: 0,
            }}
          >
            From {state.organization?.name}
          </Title>
          <ReactPlayer
            controls={true}
            height={195}
            url={state.video ?? token.advisorDashboardPageVideoLink}
            width={320}
          />
        </Space>
        <CardSkeleton loading={state.loading}>
          <Space direction={"vertical"} size={20} style={{ width: "100%" }}>
            {getFeaturedMaterials()?.map(
              ({ id, featureContentHeadline, featureContentDescription }) => (
                <Space
                  className={"aside-card"}
                  key={id}
                  size={15}
                  align={"start"}
                  onClick={() => openFile(id)}
                >
                  <File style={{ height: 30 }} />
                  <Space direction="vertical" size={2}>
                    <Title
                      style={{
                        fontSize: 18,
                        lineHeight: 1,
                        color: token.colorUiText,
                        margin: 0,
                      }}
                    >
                      {featureContentHeadline}
                    </Title>
                    <Paragraph
                      style={{
                        lineHeight: 1.4,
                        color: token.colorUiText,
                        margin: 0,
                      }}
                    >
                      {featureContentDescription}
                    </Paragraph>
                  </Space>
                </Space>
              )
            )}
          </Space>
        </CardSkeleton>
      </Space>

      <ModalQuarterlyGoals
        open={state.quarterlyGoalsModal}
        onClose={() => state.closeModal("quarterlyGoalsModal")}
        handleUpdateQuarterlyGoals={handleUpdateQuarterlyGoals}
        initialState={state.quarterly}
        loading={state.loading}
      />
    </StylesContainer>
  );
};

export default PageAdvisorDashboard;
