import { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import State from "../../../../context";
import { Button, Col, Flex, Image, Layout, Row, theme } from "antd";
import CardGoalPreview from "../../components/CardGoalPreview";
import Header from "../../components/Header";
import ModalCreateGoal from "../../components/ModalCreateGoal";
import ModalProductDetails from "../../../../organisms/ModalProductDetails";
import SectionAskAdvice from "../../../../organisms/SectionAskAdvice";
import SectionIntroducingAdvice from "./components/SectionIntroducingAdvice";
import SkeletonDashboard from "../../../../molecules/SkeletonDashboard";

import { SectionAskAdvise, VideoContainer } from "./styles";

import { isLockedByAdminView } from "../../../../utils/helpers/specialized";
import { getGoalsAppOrganization } from "../../helpers";

const PageDash = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [state, setState] = useContext(State);
  const isLargeScreen = useMediaQuery({ maxWidth: 1180 });
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });

  useEffect(() => {
    if (!state.getPreferenceValue("initialRiskTolerance")) {
      state.getPreferenceValue("productMap") &&
        state.setPreferenceValue(
          "initialRiskTolerance",
          state.getPreferenceValue("productMap")[
            Object.keys(state.getPreferenceValue("productMap"))[0]
          ][0].riskTolerance
        );
    }
  }, [state]);

  const getLatestGoalPreview = () => {
    const productMap = state.getPreferenceValue("productMap");

    if (!productMap) {
      return false;
    }

    const lastGoalData =
      productMap[Object.keys(productMap)[Object.keys(productMap).length - 1]];
    const lastGoalLatestData = lastGoalData[lastGoalData.length - 1];

    return (
      <CardGoalPreview
        goalData={{
          ...lastGoalLatestData,
          objective: {
            ...lastGoalLatestData,
            organization: getGoalsAppOrganization(state.organization?.name),
          },
          organization: getGoalsAppOrganization(state.organization?.name),
          productId: lastGoalLatestData?.productId,
        }}
        config={{
          isMobile: isMobile,
          hideChart: isMobile,
        }}
        productsList={state.productsList}
      />
    );
  };

  const handleGetAdviceButtonClick = () => {
    state.setKeyValue("openModalAskAdvice", true);
    state.setKeyValue("sectionAskAdviceActiveTab", "advisor");
  };

  return (
    <SkeletonDashboard loading={state.loading}>
      <Header hideNavbar={!isMobile}>
        <Image preview={false} src={token.logoSrc} width={216} />
      </Header>
      <Layout.Content>
        <Row gutter={[30, 30]} justify={"center"} wrap={isLargeScreen}>
          <Col flex={"1 1 378px"}>
            <Flex vertical gap={20}>
              {getLatestGoalPreview()}
              <Flex gap={15} justify={"space-between"}>
                <Button
                  onClick={() => navigate("/goals")}
                  shape={"round"}
                  size={isMobile && "large"}
                  style={{
                    width: 180,
                    fontSize: isMobile && 14,
                    background: "#FFFFFF",
                    color: "#24556F",
                  }}
                  type={"text"}
                >
                  View All Goals
                </Button>
                <Button
                  disabled={isLockedByAdminView({
                    managerAccess: state.managerAccess,
                  })}
                  onClick={() => state.openModal("createGoalModal")}
                  shape={"round"}
                  size={isMobile && "large"}
                  style={{
                    width: 180,
                    fontSize: isMobile && 14,
                    background: token.pageDashCreateNewGoalsButtonBackground,
                    color: token.pageDashCreateNewGoalsButtonColor,
                  }}
                >
                  + Create New Goal
                </Button>
              </Flex>
            </Flex>
          </Col>
          {!isMobile && (
            <Col flex={"1 1 380px"}>
              <SectionAskAdvise>
                <SectionAskAdvice
                  modalView={false}
                  setState={setState}
                  state={state}
                />
              </SectionAskAdvise>
            </Col>
          )}
          <Col flex={"1 1 313px"}>
            <Flex gap={32} vertical>
              <SectionIntroducingAdvice
                handleGetAdviceButtonClick={handleGetAdviceButtonClick}
                isMobile={isMobile}
                managerAccess={state.managerAccess}
              />
              <VideoContainer>
                <h4 className={"title"}>
                  How do you think about your finances?
                </h4>
                <p className={"description"}>
                  Connect with our community to learn more and figure out your
                  next best step.
                </p>
                <ReactPlayer
                  controls={true}
                  height={195}
                  id={"promoVideoContainer"}
                  style={{ marginBottom: 19 }}
                  url={token.advisorDashboardPageVideoLink}
                  width={"100%"}
                />
                <Button
                  block
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC_5ubjAqG9S_TZOM55uJRhQ",
                      "_blank"
                    )
                  }
                  shape={"round"}
                  size={"large"}
                  style={{
                    fontSize: 16,
                    background: token.pageDashAllEpisodesButtonBackground,
                    color: "#FFFFFF",
                  }}
                >
                  All Episodes
                </Button>
              </VideoContainer>
            </Flex>
          </Col>
        </Row>
      </Layout.Content>

      <ModalCreateGoal />
      <ModalProductDetails
        handleClose={() => state.closeModal("productDetailsModal")}
        hideMcSettingsToggler={true}
        investmentAssumptions={state.investmentAssumptions}
        open={state.productDetailsModal}
        organization={"goals"}
        product={{
          ...state.productsList.find(it => it._id === state.productDetailsId),
        }}
        productsList={state.productsList}
      />
    </SkeletonDashboard>
  );
};

export default PageDash;
