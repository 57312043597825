import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../context";
import { Button, Col, Dropdown, Layout, theme } from "antd";
import OverviewTab from "./components/OverviewTab";
import SimulationsTab from "./components/SimulationsTab";
import XRayTab from "./components/XRayTab";

import StyledModal, { StyledTabs } from "./styles";

import {
  DEFAULT_PRODUCT_ACCENT_COLOR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../utils/constants";

import {
  getCalculations,
  getLiveAssessment,
} from "../../utils/requests/regularApp";
import { getPercentValue } from "../../utils/helpers/general";
import {
  isOrganizationGoals,
  isProposalUser,
} from "../../utils/helpers/specialized";

import Close from "../../icons/Close";

const ModalProductDetails = ({
  actions,
  open,
  handleClose,
  product,
  productsList,
  investmentAssumptions,
  organization,
  xRayList,
  hideMcSettingsToggler,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });
  const [state] = useContext(State);
  const [liveAssessment, setLiveAssessment] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!product || !open || state.proposalViewMode === "pdf") {
      return;
    }

    setLoading(true);

    if (product?.productName === "initial") {
      getCalculations({
        currentScenario: "initial",
        incomeBarchart: true,
        client: (organization ?? isProposalUser(state)) ? "level2" : "goals",
      }).then(data => {
        setLoading(false);
        setLiveAssessment({
          ...data,
          productId: product._id,
        });
      });
    } else {
      const requestBody = {
        organization:
          organization?.toLowerCase() === "level"
            ? "level2"
            : organization?.toLowerCase(),
        productId: product._id ?? product.productId,
        objective: {
          ...investmentAssumptions,
          ...product,
          withdrawalLevel: getPercentValue(
            product.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL
          ),
        },
      };

      getLiveAssessment(requestBody)
        .then(data => {
          setLoading(false);
          setLiveAssessment({
            ...data,
            productId: product._id,
          });
        })
        .catch(({ response }) => {
          setLoading(false);
          state?.showError(response?.data?.error ?? response?.data?.message);
        });
    }
  }, [product, open]);

  const accentColor =
    productsList?.find(it => it._id === product?._id)?.color ??
    DEFAULT_PRODUCT_ACCENT_COLOR;

  const items = [
    {
      key: "Overview",
      label: `Overview`,
      children: product && (
        <OverviewTab
          closeModal={handleClose}
          investmentAssumptions={investmentAssumptions}
          liveAssessment={liveAssessment}
          loading={loading}
          product={product}
          productsList={productsList}
          viewMode={state.proposalViewMode}
        />
      ),
      visible: true,
    },
    {
      key: "XRay",
      label: `Portfolio X-Ray`,
      children: product && xRayList && (
        <XRayTab product={product} xRayList={xRayList} />
      ),
      visible: xRayList && product?.allocationType !== "ETF",
    },
    {
      key: "Simulations",
      label: `Simulations`,
      children: product && (
        <SimulationsTab
          hideMcSettingsToggler={hideMcSettingsToggler}
          investmentAssumptions={investmentAssumptions}
          liveAssessment={liveAssessment}
          organization={organization}
          product={product}
        />
      ),
      visible: !isOrganizationGoals(organization ?? "goals"),
    },
  ].filter(it => it.visible);

  const handleMenuClick = ({ item }) => item.props.onSelect(product);

  const menuProps = {
    items: actions?.filter(action => action.key !== "action-item-0"),
    onClick: handleMenuClick,
  };

  return (
    <StyledModal
      open={open}
      onCancel={handleClose}
      footer={null}
      width={product?.goalId === "emergencySavings" ? 744 : 1030}
      closeIcon={<Close color={token.colorGrey1} size={24} />}
      style={{ top: 32 }}
    >
      <Layout.Header
        style={{
          background: token.modalFinancialProductDetailsHeaderBackground,
          borderLeft: `16px solid ${accentColor}`,
        }}
      >
        <Col xs={18} sm={18} md={20} lg={20}>
          <div
            dangerouslySetInnerHTML={{
              __html: product?.assetManager ?? "Level®",
            }}
          />
          <h3 className={"title"}>
            {product?.name ?? `${product?.riskTolerance} Growth Rate`}
          </h3>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4}>
          {actions?.length > 0 && (
            <Dropdown menu={menuProps} trigger={["click"]}>
              <Button
                className={"modal-product-details-actions-menu-toggler"}
                shape={"round"}
                type={"text"}
                size={isSmallScreen ? "medium" : "small"}
                style={{
                  background: token.colorWhite,
                }}
              >
                Options
              </Button>
            </Dropdown>
          )}
        </Col>
      </Layout.Header>
      <StyledTabs
        className={"product-details-tabs"}
        defaultActiveKey={"Overview"}
        items={items}
      />
    </StyledModal>
  );
};

export default ModalProductDetails;
