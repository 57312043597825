import { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import State from "../../../../../../../../context";

import { getChartConfigPlanning } from "../../../../../../charts";

const Chart = ({ ...props }) => {
  const [state] = useContext(State);

  const perspectiveMy = state.getPreferenceValue("perspectiveMy");
  const perspectiveSpouse = state.getPreferenceValue("perspectiveSpouse");

  const getScore = points => Math.round((points / 25) * 100);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getChartConfigPlanning({
        ...props,
        series: [
          {
            color: "#4B82B1",
            data: [
              getScore(perspectiveMy?.calculation?.spendingPoints),
              getScore(perspectiveMy?.calculation?.givingPoints),
              getScore(perspectiveMy?.calculation?.savingPoints),
              getScore(perspectiveMy?.calculation?.investingPoints),
            ],
            lineColor: "transparent",
            marker: {
              enabled: false,
            },
            name: state.personalInfo?.firstName,
            pointPlacement: "on",
            type: "area",
            zIndex: 1,
          },
          {
            color: "#42C77D",
            data: [
              getScore(perspectiveSpouse?.calculation?.spendingPoints),
              getScore(perspectiveSpouse?.calculation?.givingPoints),
              getScore(perspectiveSpouse?.calculation?.savingPoints),
              getScore(perspectiveSpouse?.calculation?.investingPoints),
            ],
            lineColor: "transparent",
            marker: {
              enabled: false,
            },
            name: state.getPreferenceValue("guideInitialData")?.spouseFirstName,
            pointPlacement: "on",
            type: "area",
            zIndex: 2,
          },
        ],
        width: 390,
      })}
    />
  );
};

export default Chart;
