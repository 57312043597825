import { useContext } from "react";
import State from "../../../../../../../../context";
import { Flex, Tooltip } from "antd";
import Chart from "../Chart";

import { LIST_PERSONALITY } from "../../../../../../constants";

import { ReactComponent as IconI } from "../../images/icon_i.svg";

const TabOverview = () => {
  const [state] = useContext(State);

  const perspectiveMy = state.getPreferenceValue("perspectiveMy");
  const perspectiveSpouse = state.getPreferenceValue("perspectiveSpouse");

  return (
    <Flex gap={10}>
      <Flex className={"copy-container"} vertical>
        <h4 className={"title"}>Overview</h4>
        <div className="label">
          {state.personalInfo?.firstName}’s Planning Personality
        </div>
        <h3 className={"selected-personality"}>
          <Tooltip
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: LIST_PERSONALITY.find(
                    it => it.name === perspectiveMy?.selectedPersonality
                  )?.description,
                }}
              />
            }
          >
            <Flex align={"center"} gap={8}>
              {perspectiveMy?.selectedPersonality} <IconI />
            </Flex>
          </Tooltip>
        </h3>
        <div className="label">
          {state.getPreferenceValue("guideInitialData")?.spouseFirstName}’s
          Planning Personality
        </div>
        <h3 className={"selected-personality"}>
          <Tooltip
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: LIST_PERSONALITY.find(
                    it => it.name === perspectiveSpouse?.selectedPersonality
                  )?.description,
                }}
              />
            }
          >
            <Flex align={"center"} gap={8}>
              {perspectiveSpouse?.selectedPersonality} <IconI />
            </Flex>
          </Tooltip>
        </h3>
        <p>
          Marriage is a high calling and an imMarriage is a high calling and an
          immeasurable gift. We each bring our own Perspective on money into the
          partnership, and this presents an opportunity for either division or
          unity.
        </p>
        <p>We recommend assessing your combined Perspectives in three ways.</p>
        <ul>
          <li>
            <b>COMMON GROUND</b> - what tendencies are natural for you both
          </li>
          <li>
            <b>BLIND SPOTS</b> - what tendencies you may be missing
          </li>
          <li>
            <b>UNIQUE TENDENCIES</b> - where your tendencies diverge
          </li>
        </ul>
      </Flex>

      <Chart />
    </Flex>
  );
};

export default TabOverview;
