import { Button, Flex } from "antd";
import TableMilestones from "../SectionMilestonesQuestionnaire/components/TableMilestones";

import StyledModal from "./styles";
import {
  BoldItalicUnderlineToggles,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";

const ModalMilestones = ({
  milestonesQuestions,
  milestonesNote,
  onCancel,
  onEditMilestonesClick,
  open,
}) => (
  <StyledModal
    closeIcon={false}
    footer={
      <Flex justify={"space-between"}>
        <Button onClick={onCancel} shape={"round"} size={"large"}>
          Close
        </Button>
        <Button
          onClick={onEditMilestonesClick}
          shape={"round"}
          size={"large"}
          style={{ background: "#428916" }}
          type={"primary"}
        >
          Edit Milestones
        </Button>
      </Flex>
    }
    onCancel={onCancel}
    open={open}
    title={"Milestones"}
    width={1061}
  >
    <p className={"description"}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
      tempus ac nisi nec euismod. Praesent venenatis semper facilisis. Praesent
      in eleifend ligula, sollicitudin elementum est.{" "}
    </p>
    <Flex gap={32}>
      <Flex gap={28} vertical>
        <Flex vertical>
          <h4>Marriage & Career</h4>
          <TableMilestones
            data={milestonesQuestions?.filter(
              it => (it.type === "marriage" || it.type === "career") && it.value
            )}
            readonly
          />
        </Flex>
        <Flex vertical>
          <h4>Health & Family</h4>
          <TableMilestones
            data={milestonesQuestions?.filter(
              it => (it.type === "health" || it.type === "family") && it.value
            )}
            hideTitle
            readonly
          />
        </Flex>
        <Flex vertical>
          <h4>Assets, Liabilities & Generosity</h4>
          <TableMilestones
            data={milestonesQuestions?.filter(
              it =>
                (it.type === "assets" || it.type === "generosity") && it.value
            )}
            hideTitle
            readonly
          />
        </Flex>
      </Flex>
      <Flex vertical>
        <div className={"sub-title"}>Notes</div>
        <MDXEditor
          className={"markdown-container"}
          markdown={milestonesNote}
          plugins={[
            listsPlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                  <ListsToggle />
                </>
              ),
            }),
          ]}
        />
      </Flex>
    </Flex>
  </StyledModal>
);

export default ModalMilestones;
