import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import State from "../../../../context";
import { Avatar, theme } from "antd";
import MenuActions from "../../../../atoms/MenuActions";

import { logout } from "../../../../utils/requests/regularApp";
import { isProposalUser } from "../../../../utils/helpers/specialized";

import { UserOutlined } from "@ant-design/icons";

const MenuGoalsUser = () => {
  const { token } = theme.useToken();
  const isTablet = useMediaQuery({ maxWidth: token.screenLGMin });
  const navigate = useNavigate();
  const [state] = useContext(State);

  const getItems = () =>
    [
      {
        key: "1",
        label: (
          <div onClick={() => state.openModal("profileSettingsModal")}>
            Profile
          </div>
        ),
        hidden: isProposalUser(state),
      },
      {
        key: "2",
        label: (
          <div onClick={() => state.openModal("contactUsModal")}>
            Contact Us
          </div>
        ),
      },
      {
        type: "divider",
      },
      {
        key: "3",
        label: (
          <div
            onClick={() =>
              logout()
                .then(() => navigate("/login"))
                .catch(console.log)
            }
          >
            Log Out
          </div>
        ),
      },
    ].filter(it => !it.hidden);

  return (
    <>
      {state.personalInfo ? (
        <MenuActions items={isTablet ? [] : getItems()}>
          <Avatar
            onClick={() => isTablet && state.openModal("profileSettingsModal")}
            size={41}
            style={{
              backgroundColor: token.sidebarAvatarBackground,
              color: token.sidebarAvatarColor,
            }}
          >
            {state.personalInfo &&
              (state.personalInfo.firstName ?? state.personalInfo.lastName)[0]}
          </Avatar>
        </MenuActions>
      ) : (
        <Avatar
          size={35}
          style={{
            backgroundColor: "#ADB7C5",
            color: "#3A5078",
            cursor: "waiting",
          }}
          icon={<UserOutlined />}
        />
      )}
    </>
  );
};

export default MenuGoalsUser;
