import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../../context";
import { Button, Flex, Layout, theme } from "antd";
import CardGoalPreview from "../../components/CardGoalPreview";
import Header from "../../components/Header";
import ModalCreateGoal from "../../components/ModalCreateGoal";
import ModalProductDetails from "../../../../organisms/ModalProductDetails";
import SkeletonDashboard from "../../../../molecules/SkeletonDashboard";

import { isLockedByAdminView } from "../../../../utils/helpers/specialized";
import { getGoalsAppOrganization } from "../../helpers";

const PageGoals = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });

  return (
    <SkeletonDashboard loading={state.loading}>
      <Header>
        <Flex align={"center"} gap={20}>
          <b className={"title"}>Your goals</b>
          <Button
            disabled={isLockedByAdminView({
              managerAccess: state.managerAccess,
            })}
            onClick={() => state.openModal("createGoalModal")}
            shape={"round"}
            type={"primary"}
          >
            + New Goal
          </Button>
        </Flex>
      </Header>
      <Layout.Content>
        <Flex align={"start"} gap={32} justify={"center"} wrap={"wrap"}>
          {state.getPreferenceValue("productMap") &&
            Object.keys(state.getPreferenceValue("productMap")).map(
              (key, index) => {
                const productMap = state.getPreferenceValue("productMap");
                const lastDataSnapshot =
                  productMap[key][productMap[key].length - 1];

                return (
                  <div key={index} style={{ width: isMobile ? "100%" : 289 }}>
                    <CardGoalPreview
                      config={{
                        isMobile,
                        hideChart: isMobile,
                      }}
                      goalData={{
                        ...lastDataSnapshot,
                        objective: {
                          ...lastDataSnapshot,
                          organization: getGoalsAppOrganization(
                            state.organization?.name
                          ),
                        },
                        organization: getGoalsAppOrganization(
                          state.organization?.name
                        ),
                        productId: lastDataSnapshot.productId,
                      }}
                      productsList={state.productsList}
                    />
                  </div>
                );
              }
            )}
        </Flex>
      </Layout.Content>

      <ModalCreateGoal />
      <ModalProductDetails
        handleClose={() => state.closeModal("productDetailsModal")}
        hideMcSettingsToggler={true}
        investmentAssumptions={state.investmentAssumptions}
        open={state.productDetailsModal}
        organization={"goals"}
        product={{
          ...state.productsList.find(it => it._id === state.productDetailsId),
        }}
        productsList={state.productsList}
      />
    </SkeletonDashboard>
  );
};

export default PageGoals;
