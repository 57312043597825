import { useContext } from "react";
import { useLocation } from "react-router-dom";
import State from "../../../../context";
import ModalManagedUserData from "../../../../organisms/ModalManagedUserData";
import SecurityGate from "../../../../providers/SecurityGate";
import SectionClientDetails from "./components/SectionClientDetails";
import SectionClientsList from "./components/SectionClientsList";

const PageNewNextLevelClients = () => {
  const location = useLocation();
  const [state] = useContext(State);

  return (
    <SecurityGate state={state}>
      {location.state?.clientEmail ? (
        <SectionClientDetails
          clientData={state.managedUsers?.find(
            it => it.email === location.state.clientEmail
          )}
        />
      ) : (
        <SectionClientsList />
      )}

      <ModalManagedUserData
        handleClose={() => state.setKeyValue("openModalAddManagedUser", false)}
        isLevelView={true}
        open={state.openModalAddManagedUser}
      />
    </SecurityGate>
  );
};

export default PageNewNextLevelClients;
