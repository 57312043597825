import { useContext, useState } from "react";
import State from "../../context";
import QuestionnaireContentAstor from "../../ecosystems/astor/components/QuestionnaireContentAstor";
import QuestionnaireContentDefault from "./components/QuestionnaireContentDefault";
import QuestionnaireContentIPQ from "../../ecosystems/oneascent/components/QuestionnaireContentIPQ";
import QuestionnaireContentRivershares from "../../ecosystems/rivershares/components/QuestionnaireContentRivershares";
import QuestionnaireContentWealt from "../../ecosystems/oneascent/components/QuestionnaireContentWealt";
import ModalDisclosures from "./components/ModalDisclosures";

import StyledModal from "./styles";

import { DEFAULT_BIRTH_YEAR } from "../../utils/constants";

import { openProposal } from "../../utils/requests/manager";
import { updateProductObjective } from "../../utils/requests/regularApp";
import {
  isOrganizationAstor,
  isOrganizationLevel,
  isOrganizationRivershares,
} from "../../utils/helpers/specialized";

const ModalProposalGuideManager = ({ open, handleClose }) => {
  const [state] = useContext(State);
  const [showDisclosuresModal, setShowDisclosuresModal] = useState(false);
  const organization = state.organization?.name ?? state.orgName;

  const handleCreateProposal = proposalData => {
    state.setKeyValue("loading", true);
    handleClose();

    updateProductObjective({
      email: state.activeEmail,
      ...proposalData,
      birthYear:
        state.managedUsers?.find(it => it.email === state.activeEmail)
          ?.birthYear ||
        proposalData.birthYear ||
        DEFAULT_BIRTH_YEAR,
    })
      .then(() => {
        state.setAdminData().then(() => {
          state.showSuccess("New proposal created!");
          state.setKeyValue("loading", false);
          openProposal({
            userEmail: state.activeEmail,
            organization: proposalData.organization,
          });
        });
      })
      .catch(error => {
        state.showError(error.response?.data);
        state.setKeyValue("loading", false);
      });
  };

  const handleIpqSaveContinueButton = ({ proposalData, callback }) => {
    state.setKeyValue("loading", true);

    saveUserGuideData(proposalData).then(() => {
      state.setAdminData().then(() => {
        state.showSuccess("Questionnaire data saved");
        callback && callback();
        state.setKeyValue("loading", false);
      });
    });
  };

  const handleIpqSaveExitButton = ({ proposalData, callback }) => {
    state.setKeyValue("loading", true);

    saveUserGuideData({
      ...proposalData,
      investmentAmount: null,
      contributions: null,
      investmentDuration: null,
      householdIncome: null,
    }).then(() => {
      state.setAdminData().then(() => {
        state.showSuccess("Questionnaire data saved");
        callback && callback();
        handleClose();
        state.setKeyValue("loading", false);
      });
    });
  };

  const saveUserGuideData = customProposalData => {
    const activeUserData = state.managedUsers?.find(
      it => it.email === state.activeEmail
    );

    return updateProductObjective({
      email: state.activeEmail,
      ...(activeUserData.valueMap.prospectObjective ?? {}),
      ...customProposalData,
    }).catch(error => {
      state.showError(error.response?.data);
      state.setKeyValue("loading", false);
    });
  };

  return (
    <StyledModal open={open} width="100vw" footer={false} closable={false}>
      {state.onboardingQuestionnaireType === "wealthManagement" && (
        <QuestionnaireContentWealt
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
          setShowDisclosuresModal={setShowDisclosuresModal}
        />
      )}
      {state.onboardingQuestionnaireType === "IPQ" && (
        <QuestionnaireContentIPQ
          handleCreateProposal={handleCreateProposal}
          handleIpqSaveContinueButton={handleIpqSaveContinueButton}
          handleIpqSaveExitButton={handleIpqSaveExitButton}
          loading={state.loading}
          newIpqMode={state.newIpqMode}
          onClose={handleClose}
          open={open}
          setKeyValue={state.setKeyValue}
          setShowDisclosuresModal={setShowDisclosuresModal}
          showError={state.showError}
          showWarning={state.showWarning}
          userData={state.managedUsers?.find(
            it => it.email === state.activeEmail
          )}
        />
      )}
      {isOrganizationRivershares(organization) && (
        <QuestionnaireContentRivershares
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      )}
      {isOrganizationAstor(organization) && (
        <QuestionnaireContentAstor
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      )}
      {isOrganizationLevel(organization) && (
        <QuestionnaireContentDefault
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      )}

      <ModalDisclosures
        handleClose={() => setShowDisclosuresModal(false)}
        open={showDisclosuresModal}
      />
    </StyledModal>
  );
};

export default ModalProposalGuideManager;
