import { useContext } from "react";
import State from "../../../../context";
import SectionMilestonesQuestionnaire from "./components/SectionMilestonesQuestionnaire";
import SectionPerspectiveCoupleResults from "./components/SectionPerspectiveCoupleResults";
import SectionPerspectiveIndividualResults from "./components/SectionPerspectiveIndividualResults";
import SectionPrioritiesCoupleResults from "./components/SectionPrioritiesCoupleResults";
import SectionWorkspace from "./components/SectionWorkspace";

const PageDashboard = () => {
  const [state] = useContext(State);

  return state.viewPerspectiveIndividualResults ? (
    <SectionPerspectiveIndividualResults />
  ) : state.viewPerspectiveCoupleResults ? (
    <SectionPerspectiveCoupleResults />
  ) : state.viewPrioritiesCoupleResults ? (
    <SectionPrioritiesCoupleResults />
  ) : state.viewMilestonesQuestionnaire ? (
    <SectionMilestonesQuestionnaire />
  ) : (
    <SectionWorkspace />
  );
};

export default PageDashboard;
