import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../../../context";
import { Button, Flex } from "antd";
import ModalMilestones from "../ModalMilestones";
import ModalPerspectives from "../ModalPerspectives";
import ModalPriorities from "../ModalPriorities";
import ModalProposalGuideUser from "../../../../components/ModalProposalGuideUser";
import ModalViewIpq from "../../../../components/ModalViewIpq";

import StylesContainer from "./styles";

import { shouldShowMilestones } from "../../../../helpers";

const SectionWorkspace = () => {
  const navigate = useNavigate();
  const [state] = useContext(State);

  const guideInitialData = state.getPreferenceValue("guideInitialData");

  const onEditMilestonesClick = () => {
    state.setKeyValue("openModalMilestones", false);
    showMilestonesQuestionnaire();
  };

  const handleLaunchIpq = () => {
    state.setKeyValue("openModalViewIpq", true);
  };

  const handleLaunchMilestones = () => {
    if (state.getPreferenceValue("milestonesQuestions")) {
      state.setKeyValue("openModalMilestones", true);
    } else {
      showMilestonesQuestionnaire();
    }
  };

  const showMilestonesQuestionnaire = () => {
    state.setKeyValue("viewMilestonesQuestionnaire", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleLaunchPerspectives = perspectiveFor => {
    state.setKeyValue("perspectiveFor", perspectiveFor);
    state.setKeyValue("openModalPerspectives", true);
  };

  const handleLaunchPriorities = prioritiesFor => {
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("openModalPriorities", true);
  };

  const handleNewProposal = () => {
    if (state.getPreferenceValue("productMap")) {
      // proposal already saved
      state.setKeyValue("showOverlay", true);
      navigate("/oneascent-proposal/goals");

      setTimeout(() => {
        state.setKeyValue("activeGoal", undefined);
        state.setKeyValue("proposalViewMode", "goal");
        state.setKeyValue("showOverlay", false);
      }, 1000);
    } else {
      // proposal do not saved yet
      state.setKeyValue("activeEmail", state._id);
      state.setKeyValue("openModalProposalGuide", true);
    }
  };

  const handlePerspectivesComplete = perspectives => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue(state.perspectiveFor, perspectives)
      .then(() => {
        state.showSuccess("Perspectives saved");
      })
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const handlePrioritiesComplete = priorities => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue(state.prioritiesFor, priorities)
      .then(() => {
        state.showSuccess("Priorities saved");
      })
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const handleViewPerspectiveCouple = () => {
    state.setKeyValue("viewPerspectiveCoupleResults", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleViewPerspectivesResults = perspectiveType => {
    state.setKeyValue("viewPerspectiveIndividualResults", perspectiveType);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleViewPrioritiesCouple = () => {
    state.setKeyValue("viewPrioritiesCoupleResults", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const ButtonViewPerspectivesMy = () =>
    state.getPreferenceValue("perspectiveMy")?.selectedPersonality ? (
      <Button
        onClick={() => handleViewPerspectivesResults("perspectiveMy")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {state.personalInfo?.firstName}'s
      </Button>
    ) : state.getPreferenceValue("perspectiveMy") ? (
      <Button
        onClick={() => handleViewPerspectivesResults("perspectiveMy")}
        shape={"round"}
        type={"custom"}
      >
        <Flex gap={10}>
          <span className={"perspective-step-marker"}>1/2</span>
          {state.personalInfo?.firstName}: Launch
        </Flex>
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPerspectives("perspectiveMy")}
        shape={"round"}
        type={"custom"}
      >
        <Flex gap={10}>{state.personalInfo?.firstName}: Launch</Flex>
      </Button>
    );

  const ButtonViewPerspectivesSpouse = () =>
    state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality ? (
      <Button
        onClick={() => handleViewPerspectivesResults("perspectiveSpouse")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {guideInitialData?.spouseFirstName}’s
      </Button>
    ) : state.getPreferenceValue("perspectiveSpouse") ? (
      <Button
        onClick={() => handleViewPerspectivesResults("perspectiveSpouse")}
        shape={"round"}
        type={"custom"}
      >
        <Flex gap={10}>
          <span className={"perspective-step-marker"}>1/2</span>
          {guideInitialData?.spouseFirstName}: Launch
        </Flex>
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPerspectives("perspectiveSpouse")}
        shape={"round"}
        type={"custom"}
      >
        <Flex gap={10}>{guideInitialData?.spouseFirstName}: Launch</Flex>
      </Button>
    );

  const ButtonViewPerspectivesCouple = () =>
    state.getPreferenceValue("perspectiveMy")?.selectedPersonality &&
    state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality ? (
      <Button
        onClick={handleViewPerspectiveCouple}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button disabled shape={"round"} type={"custom"}>
        View {state.personalInfo?.lastName}'s
      </Button>
    );

  const ButtonViewPrioritiesMy = () =>
    state.getPreferenceValue("prioritiesMy") ? (
      <Button
        onClick={() => handleLaunchPriorities("prioritiesMy")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {state.personalInfo?.firstName}’s
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPriorities("prioritiesMy")}
        shape={"round"}
        type={"custom"}
      >
        {state.personalInfo?.firstName}: Launch
      </Button>
    );

  const ButtonViewPrioritiesSpouse = () =>
    state.getPreferenceValue("prioritiesSpouse") ? (
      <Button
        onClick={() => handleLaunchPriorities("prioritiesSpouse")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {guideInitialData?.spouseFirstName}’s
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPriorities("prioritiesSpouse")}
        shape={"round"}
        type={"custom"}
      >
        {guideInitialData?.spouseFirstName}: Launch
      </Button>
    );

  const ButtonViewPrioritiesCouple = () =>
    state.getPreferenceValue("prioritiesFamily") ? (
      <Button
        onClick={handleViewPrioritiesCouple}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button
        disabled={
          !state.getPreferenceValue("prioritiesMy") ||
          !state.getPreferenceValue("prioritiesSpouse")
        }
        onClick={handleViewPrioritiesCouple}
        shape={"round"}
        type={"custom"}
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    );

  return (
    <StylesContainer gap={40} vertical>
      <Flex align={"end"} justify={"space-between"}>
        <Flex className={"header"} gap={11} vertical>
          <h3 className={"title"}>
            Welcome, {state.personalInfo?.firstName}
            {guideInitialData?.spouseFirstName
              ? ` & ${guideInitialData?.spouseFirstName}`
              : ""}
          </h3>
          <p className={"description"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            tempus ac nisi nec euismod. Praesent venenatis semper facilisis.
            Praesent in eleifend ligula, sollicitudin elementum est.{" "}
          </p>
        </Flex>
        <Button disabled shape={"round"} type={"primary"}>
          Create Report
        </Button>
      </Flex>
      <Flex className={"body"} gap={32} wrap={"wrap"}>
        <Flex className={"card-next-up"}>
          <Flex gap={16} vertical>
            <h5 className={"title"}>Perspectives</h5>
            <p className={"description"}>
              We’re all uniquely made. How do you think and feel about money
              today?
              <br />
              <br />
              Should take less than 2 minutes per person.
            </p>
          </Flex>
          <Flex gap={16} vertical>
            <ButtonViewPerspectivesMy />
            {guideInitialData?.isMarried && (
              <>
                <ButtonViewPerspectivesSpouse />
                <ButtonViewPerspectivesCouple />
              </>
            )}
          </Flex>
        </Flex>
        <Flex className={"card-next-up"}>
          <Flex gap={16} vertical>
            <h5 className={"title"}>Milestones</h5>
            <p className={"description"}>
              Account for the past - plan for the future.
            </p>
          </Flex>
          <Flex align={"center"}>
            <Button
              disabled={!shouldShowMilestones()}
              onClick={handleLaunchMilestones}
              shape={"round"}
              type={"custom"}
            >
              Launch Milestones
            </Button>
          </Flex>
        </Flex>
        <Flex className={"card-next-up"}>
          <Flex gap={16} vertical>
            <h5 className={"title"}>Generosity</h5>
            <p className={"description"}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
            </p>
          </Flex>
          <Flex align={"center"}>
            <Button shape={"round"} type={"custom"}>
              Visit OneGive
            </Button>
          </Flex>
        </Flex>
        <Flex className={"card-next-up"}>
          <Flex gap={16} vertical>
            <h5 className={"title"}>Priorities</h5>
            <p className={"description"}>
              Our why helps us stay on track. What matters most to you?
              <br />
              <br />
              Should take less than 2 minutes per person.
            </p>
          </Flex>
          <Flex gap={16} vertical>
            <ButtonViewPrioritiesMy />
            {guideInitialData?.isMarried && (
              <>
                <ButtonViewPrioritiesSpouse />
                <ButtonViewPrioritiesCouple />
              </>
            )}
          </Flex>
        </Flex>
        <Flex className={"card-next-up"}>
          <Flex gap={24} vertical>
            <h5 className={"title"}>Investments</h5>
            <p className={"description"}>
              Our why helps us stay on track. What matters most to you? Should
              take less than 2 minutes per person.{" "}
            </p>
          </Flex>
          <Flex justify={"end"} gap={16} vertical>
            {state.userManagerData?.onboardingQuestionnaireType === "IPQ" && (
              <Button onClick={handleLaunchIpq} shape={"round"} type={"custom"}>
                Launch IPQ
              </Button>
            )}
            <Button onClick={handleNewProposal} shape={"round"} type={"custom"}>
              New Proposal
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <ModalMilestones
        milestonesQuestions={state.getPreferenceValue("milestonesQuestions")}
        milestonesNote={state.getPreferenceValue("milestonesNote")}
        onCancel={() => state.setKeyValue("openModalMilestones", false)}
        onEditMilestonesClick={onEditMilestonesClick}
        open={state.openModalMilestones}
      />
      <ModalPerspectives
        handleComplete={handlePerspectivesComplete}
        handleViewResults={handleViewPerspectivesResults}
        onCancel={() => state.setKeyValue("openModalPerspectives", false)}
        open={state.openModalPerspectives}
      />
      <ModalPriorities
        handleComplete={handlePrioritiesComplete}
        initialState={state.getPreferenceValue(state.prioritiesFor)}
        onCancel={() => state.setKeyValue("openModalPriorities", false)}
        open={state.openModalPriorities}
      />
      <ModalProposalGuideUser
        open={state.openModalProposalGuide}
        handleClose={() => state.setKeyValue("openModalProposalGuide", false)}
      />
      <ModalViewIpq
        activeUserData={{
          ...state.personalInfo,
          ...state.preferences,
        }}
        onCancel={() => state.setKeyValue("openModalViewIpq", false)}
        open={state.openModalViewIpq}
      />
    </StylesContainer>
  );
};

export default SectionWorkspace;
