import LogoOneascent from "../images/logo.svg";

export default {
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#4897D1",
  colorPrimaryButtonText: "#FFFFFF",
  logoSrc: LogoOneascent,
  modalFinancialProductDetailsHeaderBackground: "#E7EBEE",
  pageLoginButtonSubmitColor: "#192849",
  proposalHeaderBackground:
    "radial-gradient(57.63% 96.38% at 86.29% 32.4%, #1799D5 0%, rgba(116, 194, 230, 0.50) 100%)",
  proposalHeaderPadding: "42px 50px 42px 40px",
  sidebarBackground: "linear-gradient(180deg, #162A4E 0%, #216A9C 100%)",
  sidebarIconColor: "#fff",
  sidebarIconColorActive: "#74C2E6",
  sidebarMenuItemHoverBackground: "#152A4E",
  tenantColorPrimary: "#4897D1",
};
