import { Input, Table } from "antd";

import StyledTable from "./styles";

import { ReactComponent as Checkbox } from "./images/checkbox.svg";
import { ReactComponent as CheckboxChecked } from "./images/checkbox_checked.svg";

const CheckboxContainer = ({
  id,
  readonly,
  toggleCheckbox,
  toggleOnValue,
  value,
}) => (
  <div
    onClick={() =>
      !readonly &&
      toggleCheckbox({
        id,
        newValue: toggleOnValue,
      })
    }
    style={{ cursor: readonly ? "default" : "pointer" }}
  >
    {value === toggleOnValue ? <CheckboxChecked /> : <Checkbox />}
  </div>
);

const TableMilestones = ({
  data,
  handleCustomNameChange,
  hideTitle,
  readonly,
  toggleCheckbox,
}) => (
  <StyledTable dataSource={data} pagination={false}>
    <Table.Column
      dataIndex={"label"}
      render={(label, allValues) =>
        allValues.id.includes("_custom") && !readonly ? (
          <Input
            onChange={e =>
              handleCustomNameChange({
                id: allValues.id,
                newLabel: e.target.value,
              })
            }
            placeholder={"Other..."}
            value={label}
          />
        ) : (
          label
        )
      }
    />
    <Table.Column
      align={"center"}
      dataIndex={"value"}
      render={(value, allValues) => (
        <CheckboxContainer
          id={allValues.id}
          readonly={readonly}
          toggleCheckbox={toggleCheckbox}
          toggleOnValue={"recent"}
          value={value}
        />
      )}
      title={!hideTitle && "Recent"}
      width={"15%"}
    />
    <Table.Column
      align={"center"}
      dataIndex={"value"}
      render={(value, allValues) => (
        <CheckboxContainer
          id={allValues.id}
          readonly={readonly}
          toggleCheckbox={toggleCheckbox}
          toggleOnValue={"upcoming"}
          value={value}
        />
      )}
      title={!hideTitle && "Upcoming"}
      width={"15%"}
    />
  </StyledTable>
);

export default TableMilestones;
