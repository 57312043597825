import { useContext, useEffect, useState } from "react";
import State from "../../../../context";
import { Space } from "antd";
import Top3ProductsPieChart from "./components/Top3ProductsPieChart";
import AnalyticValue from "../../../../atoms/AnalyticValue";

import { getCurrencyValueWithRoundSign } from "../../../../utils/helpers/general";
import {
  getAllManagedUsersOfAdvisors,
  getManagedUsersTotalProposedAmount,
} from "../../../../utils/helpers/specialized";

const AdminAdvisorsAnalyticsSection = () => {
  const [state] = useContext(State);
  const [totalProposedAum, setTotalProposedAum] = useState(0);

  useEffect(() => {
    setTotalProposedAum(
      getManagedUsersTotalProposedAmount(
        getAllManagedUsersOfAdvisors(state.managedUserManagers)
      )
    );
  }, [state]);

  return (
    <Space size={100} align="start">
      <AnalyticValue
        label={"Total Advisors"}
        value={state.managedUserManagers?.length ?? 0}
      />
      <AnalyticValue
        label={"Total Proposed AUM"}
        value={getCurrencyValueWithRoundSign(totalProposedAum)}
      />
      <Top3ProductsPieChart />
    </Space>
  );
};

export default AdminAdvisorsAnalyticsSection;
