import PageDashboard from "../pages/PageDashboard";
import PageProposal from "../../proposal/templates/Proposal";

import IconGoals from "../../../icons/IconGoals";
import IconGoalsDashboard from "../../../icons/IconGoalsDashboard";

export default [
  {
    path: "/oneascent-proposal/dashboard",
    Component: PageDashboard,
    Icon: IconGoalsDashboard,
    text: "Dash",
  },
  {
    path: "/oneascent-proposal/goals",
    Component: PageProposal,
    Icon: IconGoals,
    text: "Planning",
  },
].filter(it => !it.hidden);
