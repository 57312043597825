import { useContext } from "react";
import State from "../../../../../../context";
import { Button, Flex, Tabs } from "antd";
import TabBlindSpots from "./components/TabBlindSpots";
import TabCommonGround from "./components/TabCommonGround";
import TabFourUsesMoney from "../SectionPerspectiveIndividualResults/components/TabFourUsesMoney";
import TabOverview from "./components/TabOverview";
import TabUniqueTendencies from "./components/TabUniqueTendencies";

import StylesContainer from "./styles";

const SectionPerspectiveCoupleResults = () => {
  const [state] = useContext(State);

  const diff = (a, b) => (a > b ? a - b : b - a);

  const getCoupleCalculations = () => {
    const perspectiveMy = state.getPreferenceValue("perspectiveMy");
    const perspectiveSpouse = state.getPreferenceValue("perspectiveSpouse");

    const getScore = points => Math.round((points / 25) * 100);

    return [
      {
        type: "spending",
        difference: diff(
          getScore(perspectiveMy?.calculation?.spendingPoints),
          getScore(perspectiveSpouse?.calculation?.spendingPoints)
        ),
        total:
          getScore(perspectiveMy?.calculation?.spendingPoints) +
          getScore(perspectiveSpouse?.calculation?.spendingPoints),
      },
      {
        type: "giving",
        difference: diff(
          getScore(perspectiveMy?.calculation?.givingPoints),
          getScore(perspectiveSpouse?.calculation?.givingPoints)
        ),
        total:
          getScore(perspectiveMy?.calculation?.givingPoints) +
          getScore(perspectiveSpouse?.calculation?.givingPoints),
      },
      {
        type: "saving",
        difference: diff(
          getScore(perspectiveMy?.calculation?.savingPoints),
          getScore(perspectiveSpouse?.calculation?.savingPoints)
        ),
        total:
          getScore(perspectiveMy?.calculation?.savingPoints) +
          getScore(perspectiveSpouse?.calculation?.savingPoints),
      },
      {
        type: "investing",
        difference: diff(
          getScore(perspectiveMy?.calculation?.investingPoints),
          getScore(perspectiveSpouse?.calculation?.investingPoints)
        ),
        total:
          getScore(perspectiveMy?.calculation?.investingPoints) +
          getScore(perspectiveSpouse?.calculation?.investingPoints),
      },
    ];
  };

  const coupleCalculations = getCoupleCalculations();

  const tabs = [
    {
      label: "The Four Uses of Money",
      key: "fourUsesMoney",
      children: <TabFourUsesMoney />,
    },
    {
      label: "Overview",
      key: "overview",
      children: <TabOverview />,
    },
    {
      label: "Common Ground",
      key: "commonGround",
      children: <TabCommonGround coupleCalculations={coupleCalculations} />,
    },
    {
      label: "Blind Spots",
      key: "blindSpots",
      children: <TabBlindSpots coupleCalculations={coupleCalculations} />,
    },
    {
      label: "Unique Tendencies",
      key: "uniqueTendencies",
      children: <TabUniqueTendencies coupleCalculations={coupleCalculations} />,
    },
  ];

  return (
    <StylesContainer gap={16} vertical>
      <Flex align={"end"} className={"header"} justify={"space-between"}>
        <Flex vertical>
          <span className={"label"}>Planning Personality</span>
          <h3 className={"title"} style={{ textTransform: "capitalize" }}>
            {state.personalInfo?.firstName} &{" "}
            {state.getPreferenceValue("guideInitialData")?.spouseFirstName ??
              "--"}{" "}
            {state.personalInfo?.lastName}
          </h3>
        </Flex>
        <Button disabled shape={"round"} type={"primary"}>
          Download PDF
        </Button>
      </Flex>

      <Tabs items={tabs} tabBarGutter={8} tabPosition={"left"} type={"card"} />
    </StylesContainer>
  );
};

export default SectionPerspectiveCoupleResults;
