import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, Flex, theme } from "antd";
import {
  ChatContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
// Chat Storybook: https://chatscope.io/storybook/react/?path=/docs/documentation-introduction--docs
import ContainerHelpButtons from "./components/ContainerHelpButtons";
import PennyTips from "./components/PennyTips";
import RateResponseSection from "./components/RateResponseSection";
import TabAskAdvisor from "./components/TabAskAdvisor";

import { StyledChatContainer, StyledModal } from "./styles";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import { PENNY_FEEDBACK_FORM_URL } from "../../utils/constants";

import { isManager, isPortalSuperAdmin } from "../../utils/helpers/specialized";
import { sendQuestion } from "../../utils/requests/regularApp";
import {
  cleanFromHtmlTags,
  parsePennyResponse,
} from "../../utils/helpers/general";

import CloseOutlined from "../../icons/CloseOutlined";

let ws = null;

const SectionAskAdvice = ({ modalView = true, state, setState }) => {
  const { token } = theme.useToken();
  const [message, setMessage] = useState(state.pennyMessage ?? "");
  const [showPennyFeedback] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  useEffect(() => {
    setMessage(cleanFromHtmlTags(state.pennyMessage));

    if (
      state.openModalAskAdvice &&
      state.getAdvice?.usePenny &&
      state.getAdvice?.questionBody
    ) {
      sendMessageToPenny(state.getAdvice?.questionBody);
      setState(state => ({
        ...state,
        getAdvice: {
          ...state.getAdvice,
          questionBody: "",
        },
      }));
    }
  }, []);

  useEffect(() => {
    setMessage(cleanFromHtmlTags(state.pennyMessage));
  }, [state.pennyMessage]);

  const handleAskAdvisorSubmit = values => {
    if (!values?.questionBody) {
      state.showWarning("Please type your question before send to advisor");
      return;
    }

    const requestBody = `
    Advice Question:
      Allow Data Access: ${values.giveAdvisorAccess ? "Yes" : "No"};
      Question: ${values.questionBody}.
    `;

    sendQuestion(requestBody)
      .then(() => {
        state.showSuccess("Message Sent Successfully");
        state.setKeyValue("openModalAskAdvice", false);
      })
      .catch(error => {
        state.showError(error.response?.data);
      });
  };

  const handleAskButtonClick = activeTabName => {
    state.setKeyValue("sectionAskAdviceActiveTab", activeTabName);
  };

  const handleMessageInputClick = event => {
    if (!isManager(state) && !state.getPreferenceValue("acceptedPennyTerms")) {
      event.target?.blur();
      state.setKeyValue("openModalPennyTerms", true);
      state.setKeyValue("abortPennyTermsHandler", true);
    }
  };

  const handleModalClose = () => {
    showPennyFeedback &&
      window.open(
        PENNY_FEEDBACK_FORM_URL,
        "mywin",
        `width=${
          window.screen.width > token.screenMDMax
            ? window.screen.width / 2
            : window.screen.width
        },height=${window.screen.height}`
      );
    state.closeModal("openModalAskAdvice");
  };

  const handleKeyDown = e => {
    if (e.key === "Enter" && message) {
      sendMessageToPenny(message);
      document.querySelector(".cs-message-input__content-editor").innerText =
        "";
    }
  };

  const handlePennyTipClick = message => {
    // for <MessageInput/> do not use "value" prop because of caret issue
    // when user edit middle of the input string, caret goes at the end
    setTimeout(() => {
      document.querySelector(".cs-message-input__content-editor").innerText =
        message;
    }, 50);

    setMessage(message);

    if (!isManager(state) && !state.getPreferenceValue("acceptedPennyTerms")) {
      state.setKeyValue("openModalPennyTerms", true);
      state.setKeyValue("abortPennyTermsHandler", true);
    }
  };

  const renderMessages = data =>
    data.map((responseData, index) => (
      <Message
        id={
          responseData.sender === "Me" &&
          (index + 2 === data.length || index + 1 === data.length) &&
          "myLastMessage"
        }
        key={index}
        model={{
          sender: responseData.sender,
          direction: responseData.sender === "Penny" ? "incoming" : "outgoing",
        }}
      >
        <Message.CustomContent>
          {parsePennyResponse(responseData.message)}
          {responseData.sender === "Penny" &&
            (isPortalSuperAdmin() ? (
              <div>
                <Button
                  onClick={() => {
                    state.setKeyValue(
                      "activeConversationId",
                      responseData.mongo_id
                    );
                    state.openModal("conversationModal");
                  }}
                  shape={"round"}
                  size={"small"}
                  style={{ marginTop: 8 }}
                  type={"primary"}
                >
                  Edit Answer
                </Button>
              </div>
            ) : (
              <div>
                <RateResponseSection
                  email={state._id}
                  responseData={responseData}
                  showSuccess={state.showSuccess}
                />
              </div>
            ))}
        </Message.CustomContent>
      </Message>
    ));

  const sendMessageToPenny = dirtyMessage => {
    if (typeof dirtyMessage !== "string") {
      return;
    }

    if (ws?.readyState === 0) {
      // WebSocket.CONNECTING (0)
      // connection websocket server, message already sent, abort handling
      return;
    } else if (ws?.readyState === 1) {
      // WebSocket.OPEN (1)
      // continue handling, connection ready
    } else {
      // WebSocket.CLOSING (2)
      // WebSocket.CLOSED (3)
      // initialize connection
      connectWebSocketServer(dirtyMessage);
      return;
    }

    const cleanMessage = cleanFromHtmlTags(
      dirtyMessage.replaceAll("&nbsp;", "").replaceAll("  ", " ").trim()
    );

    setMessage("");
    state.setKeyValue("pennyTyping", true);

    state.setKeyValue(
      "pennyMessages",
      state.pennyMessages.concat({
        message: cleanMessage,
        sender: "Me",
      })
    );

    const registerMessage = JSON.stringify({
      action: "sendmessage",
      question: cleanMessage,
      email: state._id,
      originApp: "Goals",
      session: state.session,
    });
    ws.send(registerMessage);

    setTimeout(
      () => document.getElementById("myLastMessage")?.scrollIntoView(),
      200
    );
  };

  const connectWebSocketServer = dirtyMessage => {
    ws = new WebSocket(process.env.REACT_APP_PENNY_WEBSOCKET);

    ws.onopen = () => {
      console.log("Connected to the WebSocket server");

      if (dirtyMessage) {
        sendMessageToPenny(dirtyMessage);
      }
    };

    ws.onmessage = event => {
      let responseMessage = event.data;

      if (event.data[0] === '"' && event.data.slice(-1) === '"') {
        responseMessage = responseMessage.slice(1, -1);
      }

      responseMessage = responseMessage
        .replaceAll("\\u2019", "'")
        .replaceAll("\\n", "</br>");

      if (responseMessage === "responding...") {
        console.log("Aborted handling for server message: 'responding...'");
        return;
      }

      state.setKeyValue("pennyMessage", "");

      setState(oldState => {
        if (
          oldState.pennyMessages[oldState.pennyMessages.length - 1].sender ===
          "Penny"
        ) {
          return {
            ...oldState,
            pennyMessages: oldState.pennyMessages.map((it, index) => {
              if (index === oldState.pennyMessages.length - 1) {
                return {
                  message: it.message + responseMessage,
                  sender: it.sender,
                };
              } else {
                return it;
              }
            }),
          };
        } else {
          setTimeout(
            () => document.getElementById("myLastMessage")?.scrollIntoView(),
            200
          );

          return {
            ...oldState,
            pennyTyping: false,
            pennyMessages: oldState.pennyMessages.concat({
              message: responseMessage,
              sender: "Penny",
            }),
          };
        }
      });
    };

    ws.onerror = error => {
      console.log("Error in the WebSocket server =>", error);
      state.setKeyValue("pennyTyping", false);
    };

    ws.onclose = () => {
      console.log("Disconnected from the WebSocket server");
    };
  };

  return modalView ? (
    <StyledModal
      closeIcon={<CloseOutlined />}
      footer={false}
      onCancel={handleModalClose}
      open={state.openModalAskAdvice}
      title={
        <Flex vertical>
          <span style={{ fontWeight: 500, color: token.colorCleverBlack }}>
            {isPortalSuperAdmin() && "ADMIN MODE"}
          </span>
          {isSmallScreen && (
            <ContainerHelpButtons
              handleButtonClick={handleAskButtonClick}
              isAdvisorButtonActive={
                state.sectionAskAdviceActiveTab === "advisor"
              }
              isAiButtonActive={state.sectionAskAdviceActiveTab !== "advisor"}
            />
          )}
        </Flex>
      }
      width={390}
    >
      {state.sectionAskAdviceActiveTab === "advisor" ? (
        <TabAskAdvisor isModalView={true} onSubmit={handleAskAdvisorSubmit} />
      ) : (
        <StyledChatContainer
          send_message_button_background={
            token.pennySendMessageButtonBackground
          }
        >
          {!state.pennyMessages.length && (
            <PennyTips handlePennyTipClick={handlePennyTipClick} />
          )}
          <ChatContainer>
            <MessageList
              typingIndicator={
                state.pennyTyping && (
                  <TypingIndicator content="Penny is typing" />
                )
              }
            >
              {state.pennyMessages && renderMessages(state.pennyMessages)}
            </MessageList>
            <MessageInput
              attachButton={false}
              disabled={
                state.pennyTyping ||
                (!isManager(state) &&
                  !state.getPreferenceValue("acceptedPennyTerms"))
              }
              onChange={(innerHtml, textContent) => setMessage(textContent)}
              onClick={e => handleMessageInputClick(e)}
              onKeyDown={handleKeyDown}
              onSend={sendMessageToPenny}
              placeholder={"Type question…"}
              sendDisabled={!message}
            />
          </ChatContainer>
        </StyledChatContainer>
      )}
    </StyledModal>
  ) : (
    <Flex style={{ height: "100%" }} vertical>
      <div style={{ marginBottom: 31 }}>
        <ContainerHelpButtons
          handleButtonClick={handleAskButtonClick}
          isAdvisorButtonActive={state.sectionAskAdviceActiveTab === "advisor"}
          isAiButtonActive={state.sectionAskAdviceActiveTab !== "advisor"}
        />
      </div>
      {state.sectionAskAdviceActiveTab === "advisor" ? (
        <TabAskAdvisor onSubmit={handleAskAdvisorSubmit} />
      ) : (
        <>
          {!state.pennyMessages.length && (
            <PennyTips handlePennyTipClick={handlePennyTipClick} />
          )}
          <StyledChatContainer
            send_message_button_background={
              token.pennySendMessageButtonBackground
            }
          >
            <ChatContainer>
              <MessageList
                typingIndicator={
                  state.pennyTyping && (
                    <TypingIndicator content="Penny is typing" />
                  )
                }
              >
                {state.pennyMessages && renderMessages(state.pennyMessages)}
              </MessageList>
              <MessageInput
                attachButton={false}
                disabled={
                  state.pennyTyping ||
                  (!isManager(state) &&
                    !state.getPreferenceValue("acceptedPennyTerms"))
                }
                onClick={e => handleMessageInputClick(e)}
                onChange={(innerHtml, textContent) => setMessage(textContent)}
                onKeyDown={handleKeyDown}
                onSend={sendMessageToPenny}
                placeholder={"Type question…"}
                sendDisabled={!message}
              />
            </ChatContainer>
          </StyledChatContainer>
        </>
      )}
    </Flex>
  );
};

export default SectionAskAdvice;
