import { Flex, Image, Layout, Row, theme } from "antd";

import { ReactComponent as LevelLogo } from "../../../../ecosystems/level/images/logo.svg";
import { ReactComponent as OneAscentSlogan } from "../../../../ecosystems/oneascent/images/slogan_dark.svg";

import {
  isOrganizationLevel,
  isOrganizationOneascent,
} from "../../../../utils/helpers/specialized";

const AdminHeader = ({ organization }) => {
  const { token } = theme.useToken();

  return (
    <Layout.Header
      style={{
        height: "fit-content",
        background: "#FFFFFF",
        padding: "32px 100px 19px 47px",
        borderBottom: `1px solid ${token.adminHeaderBorderBottomColor}`,
      }}
    >
      <Flex justify={"space-between"}>
        <Image
          id={"headerLogo"}
          preview={false}
          src={token.logoSrc}
          style={{ maxWidth: 230 }}
        />
        {isOrganizationLevel(organization) && (
          <Row>
            <LevelLogo />
          </Row>
        )}
        {isOrganizationOneascent(organization) && (
          <OneAscentSlogan id={"oneascentSlogan"} style={{ width: 160 }} />
        )}
      </Flex>
    </Layout.Header>
  );
};

export default AdminHeader;
