import { useContext, useState } from "react";
import State from "../../../../../../context";
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Button, Flex, Image } from "antd";
import SortableCard, { getCleanSortableLabel } from "../SortableCard";
import SortableColumn from "../SortableColumn";

import StylesContainer from "./styles";

import { PICK_PRIORITY_LIMIT } from "../ModalPriorities/constant";

import couple from "./images/couple.png";

const SectionPrioritiesCoupleResults = () => {
  const [state] = useContext(State);
  const [activeId, setActiveId] = useState();

  const getDefaultValues = () =>
    state.getPreferenceValue("prioritiesFamily") ?? {
      commonPicks: [],
      myPicks: state
        .getPreferenceValue("prioritiesMy")
        ?.picked?.map(it => it + "[my]"),
      spousePicks: state
        .getPreferenceValue("prioritiesSpouse")
        ?.picked?.map(it => it + "[spouse]"),
    };

  const [items, setItems] = useState(getDefaultValues());

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const findContainer = id => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find(key => items[key].includes(id));
  };

  const handleDragStart = event => {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  };

  const handleDragOver = event => {
    const { active, over, activatorEvent } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer ||
      overContainer !== "commonPicks" //allow to drop only in the middle section
    ) {
      return;
    }

    setItems(prev => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          activatorEvent.offsetX > over.rect.top + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].map(
            item => (item === active.id ? item + "[disabled]" : item) // disable just dropped item in the initial column
          ),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
        //disable same as selected value in the opposite column
        [activeContainer === "myPicks" ? "spousePicks" : "myPicks"]: prev[
          activeContainer === "myPicks" ? "spousePicks" : "myPicks"
        ].map(it =>
          getCleanSortableLabel(it) === getCleanSortableLabel(active.id)
            ? it + "[disabled]"
            : it
        ),
      };
    });
  };

  const handleDragEnd = event => {
    const { active = {}, over = {} } = event;
    const { id } = active;
    const { id: overId } = over ?? {};

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems(items => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    setActiveId(null);
  };

  const handleSave = () => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue("prioritiesFamily", items)
      .then(() => {
        state.showSuccess("Family Priorities saved");
      })
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const handleUndo = () => {
    setItems({
      commonPicks: [],
      myPicks: state
        .getPreferenceValue("prioritiesMy")
        ?.picked?.map(it => it + "[my]"),
      spousePicks: state
        .getPreferenceValue("prioritiesSpouse")
        ?.picked?.map(it => it + "[spouse]"),
    });
  };

  return (
    <StylesContainer vertical>
      <Flex align={"end"} className={"header"} gap={30}>
        <Flex vertical>
          <h3>Priorities</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            tempus ac nisi nec euismod. Praesent venenatis semper facilisis.
            Praesent in eleifend ligula, sollicitudin elementum est.{" "}
          </p>
        </Flex>
        <Image preview={false} src={couple} />
      </Flex>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <Flex gap={32} justify={"center"}>
          <Flex vertical>
            <h5 className={"column-title"}>
              {state.personalInfo?.firstName}’s Priorities
            </h5>
            <SortableColumn
              disabled={items.commonPicks?.length === PICK_PRIORITY_LIMIT}
              id={"myPicks"}
              items={items.myPicks}
              showIndex
            />
          </Flex>
          <Flex vertical>
            <h5 className={"column-title"}>
              {state.personalInfo?.lastName} Family Priorities
            </h5>
            <SortableColumn
              id={"commonPicks"}
              items={items.commonPicks}
              showIndex
            />
          </Flex>
          <Flex vertical>
            <h5 className={"column-title"}>
              {state.getPreferenceValue("guideInitialData")?.spouseFirstName}
              ’s Priorities
            </h5>
            <SortableColumn
              disabled={items.commonPicks?.length === PICK_PRIORITY_LIMIT}
              id={"spousePicks"}
              items={items.spousePicks}
              showIndex
            />
          </Flex>
        </Flex>
        <DragOverlay>
          {activeId ? <SortableCard label={activeId} /> : null}
        </DragOverlay>
      </DndContext>
      <Flex className={"footer"} gap={32} justify={"center"}>
        <Button
          disabled={!items.commonPicks?.length}
          onClick={handleUndo}
          shape={"round"}
          size={"large"}
        >
          Undo
        </Button>
        <Button
          disabled={
            state.loading || items.commonPicks?.length !== PICK_PRIORITY_LIMIT
          }
          onClick={handleSave}
          shape={"round"}
          size={"large"}
          style={{ width: 357 }}
          type={"primary"}
        >
          Save Priorities
        </Button>
      </Flex>
    </StylesContainer>
  );
};

export default SectionPrioritiesCoupleResults;
