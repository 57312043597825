import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import State from "../context";
import { notification } from "antd";
import Overlay from "../atoms/Overlay";

import defaultDashboardStateData from "../context/defaultState.json";

import {
  getManagerPendoAccountId,
  getManagerPendoVisitorMetadata,
  initializePendo,
  isGoalsDomain,
  isOrganizationLevel,
  isOrganizationOneascent,
  isProposalUser,
  setTenantSpecificHeadAttributes,
} from "../utils/helpers/specialized";
import {
  getFinancialProducts,
  getSubscriptionType,
  getUserData,
  saveUserPreferences,
} from "../utils/requests/regularApp";
import UI from "./UI";
import {
  editLead,
  getOrganizationManagerData,
  getUserManagerData,
} from "../utils/requests/manager";
import { getGoalsAppOrganization } from "../ecosystems/goals/helpers";

const StateProvider = ({ children, manager, organization }) => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [state, setState] = useState(defaultDashboardStateData);

  useEffect(() => {
    setTenantSpecificHeadAttributes(organization);
    setKeyValue("session", uuidv4());
  }, []);

  useEffect(() => {
    setKeyValue("showOverlay", true);

    if (manager) {
      setKeyValue("isManager", true);
      setKeyValue("artBoardModal", true);

      setAdminData().then(data => {
        if (!data?._id) {
          navigate("/login");
        } else {
          initializePendo(getManagerPendoVisitorMetadata(data), {
            id: getManagerPendoAccountId(data),
          });

          getSubscriptionType(data._id).then(subscriptionTypeResponse => {
            setState(lastState => ({
              ...lastState,
              ...subscriptionTypeResponse,
            }));

            getFinancialProducts(data.organization?.name ?? data.orgName).then(
              data => {
                setKeyValue("productsList", data);
                setKeyValue("showOverlay", false);
              }
            );
          });

          data.managedUsers?.map(it => {
            if (
              it?.valueMap?.leadInitial?.isNewLead &&
              it?.valueMap?.leadInitial?.isNotifyAdvisor
            ) {
              api.info({
                message: "A new lead has been added to your Leads page",
                placement: "topRight",
                description: `Lead email: ${it.email}`,
              });
              editLead({
                ...it,
                leadInitial: {
                  ...it.valueMap.leadInitial,
                  isNewLead: false,
                },
              });
            }
          });
        }
      });
    } else {
      setUserData().then(data => {
        if (data?._id) {
          if (isGoalsDomain()) {
            initializePendo({ id: data._id }, { id: "Goals" });
          } else if (isProposalUser(data)) {
            if (isOrganizationOneascent(data.organization?.name))
              initializePendo(
                {
                  id: data.userManagement.linkedUserManagers[0].email,
                  email: data.userManagement.linkedUserManagers[0].email,
                  firstname: "",
                  lastname: "",
                  plantype: "Advisor",
                  advisorview: `Client proposal: ${
                    data.personalInfo?.firstName ?? ""
                  } ${data.personalInfo?.lastName ?? ""}`,
                },
                { id: `${data.organization?.name} Asset Manager` }
              );
          }

          if (data.preferences?.valueMap?.level2Map) {
            setState(prevState => ({
              ...prevState,
              investmentAssumptions: {
                ...prevState.investmentAssumptions,
                ...data.preferences.valueMap.level2Map["initial"],
              },
            }));
          }

          getFinancialProducts(
            isOrganizationLevel(organization)
              ? "Level"
              : getGoalsAppOrganization(
                  organization ?? data.organization?.name ?? data.orgName
                )
          )
            .then(data => setKeyValue("productsList", data))
            .catch(console.log);

          if (data.userManagement?.linkedUserManagers?.length) {
            getSubscriptionType(data.userManagement.linkedUserManagers[0].email)
              .then(response => setKeyValue("managerAccess", response?.access))
              .catch(console.log);
          }

          setKeyValue("showOverlay", false);
        } else {
          navigate("/login");
        }
      });
    }
  }, []);

  const closeModal = modalKey => {
    setState(prevState => ({
      ...prevState,
      [modalKey]: false,
    }));
  };

  const getPreferenceValue = searchKey =>
    state.preferences?.valueMap && state.preferences.valueMap[searchKey];

  const openModal = modalKey =>
    setState(prevState => ({
      ...prevState,
      [modalKey]: true,
    }));

  const setAdminData = () =>
    (localStorage.getItem("collection") === "OrgManager"
      ? getOrganizationManagerData
      : getUserManagerData)()
      .then(data => {
        setState(oldState => ({
          ...oldState,
          ...data,
          managedUserManagers: data.managedUserManagers ?? [],
          loading: false,
        }));

        return data;
      })
      .catch(({ message }) => {
        showWarning(message);
        setTimeout(() => navigate("/login"), 3000);
      });

  const setKeyValue = (key, value) =>
    setState(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const setPreferenceValue = (key, value) =>
    saveUserPreferences({
      ...state.preferences,
      valueMap: {
        ...state.preferences.valueMap,
        [key]: value,
      },
    })
      .then(() => setUserData())
      .catch(error => console.log(error));

  const setUserData = () =>
    getUserData()
      .then(data => {
        setState(prevState => ({
          ...prevState,
          ...data,
        }));

        return data;
      })
      .catch(({ message }) => {
        showWarning(message);
        setTimeout(() => navigate("/login"), 3000);
      });

  const showError = errorMessage =>
    api.error({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });

  const showSuccess = successMessage =>
    api.success({
      message: successMessage,
      placement: "topRight",
      description: "",
    });

  const showWarning = errorMessage =>
    api.warning({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });

  const mergedState = {
    ...state,
    closeModal,
    getPreferenceValue,
    openModal,
    setAdminData,
    setKeyValue,
    setPreferenceValue,
    setUserData,
    showError,
    showSuccess,
    showWarning,
  };

  return (
    <State.Provider value={[mergedState, setState]}>
      <UI state={state}>
        <Overlay loading={state.showOverlay}>{children}</Overlay>
      </UI>
      {contextHolder}
    </State.Provider>
  );
};

export default StateProvider;
