import { useContext, useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import State from "../../../../context";
import { Button, InputNumber, Layout, Row, Space } from "antd";
import Bullet from "../../../../atoms/Bullet";
import GuideDisclaimer from "../../../../atoms/GuideDisclaimer";
import InputContainer from "../../../../atoms/InputContainer";

import {
  Description,
  StyledButton,
  StyledTitle,
  SubDescription,
  SubTitle,
} from "./styles";

import { defaultProposalData, stepsCopy } from "./constants";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../utils/helpers/general";
import { isGrowthTypeRiskTolerance } from "../../../../utils/helpers/specialized";

import { ReactComponent as LevelLogo } from "../../../../ecosystems/level/images/logo.svg";

const QuestionnaireContentDefault = ({
  open,
  handleClose,
  handleCreateProposal,
}) => {
  const [state] = useContext(State);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [proposalData, setProposalData] = useState(defaultProposalData);

  useEffect(() => {
    if (!open) {
      setCurrentStepIndex(0);
      setProposalData(defaultProposalData);
    }
  }, [open]);

  const { title, description, rightSideImage, subTitle, subDescription } =
    stepsCopy[currentStepIndex];

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  const isContinueButtonDisabled = () =>
    (currentStepIndex === 0 && !proposalData.investmentObjective) ||
    (currentStepIndex === 1 && !proposalData.riskTolerance);

  return (
    <Row className="guide-container" justify={"center"}>
      <Layout.Content className="guide-left-section">
        <Row
          style={{ flexDirection: "column", height: "100%" }}
          justify="space-between"
          wrap={false}
        >
          <Space direction="vertical" style={{ padding: 2 }}>
            <LevelLogo />
            <StyledTitle>{title}</StyledTitle>
            <Description>{description}</Description>
            <Space
              direction="vertical"
              size={24}
              style={{ width: 308, marginTop: 40 }}
            >
              {currentStepIndex === 0 && (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className={
                      isGrowthTypeRiskTolerance(
                        proposalData.investmentObjective
                      ) && "active"
                    }
                    block
                    onClick={() =>
                      updateProposalData("investmentObjective", "growth")
                    }
                  >
                    Drive Growth
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className={
                      proposalData.investmentObjective === "income" && "active"
                    }
                    block
                    onClick={() =>
                      updateProposalData("investmentObjective", "income")
                    }
                  >
                    Drive Income
                  </Button>
                </>
              )}
              {currentStepIndex === 1 && (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    block
                    className={
                      proposalData.riskTolerance === "Aggressive" && "active"
                    }
                    onClick={() =>
                      updateProposalData("riskTolerance", "Aggressive")
                    }
                  >
                    Aggressive
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    block
                    className={
                      proposalData.riskTolerance === "Moderate" && "active"
                    }
                    onClick={() =>
                      updateProposalData("riskTolerance", "Moderate")
                    }
                  >
                    Moderate
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    block
                    className={
                      proposalData.riskTolerance === "Conservative" && "active"
                    }
                    onClick={() =>
                      updateProposalData("riskTolerance", "Conservative")
                    }
                  >
                    Conservative
                  </Button>
                  <Button
                    shape="round"
                    size="large"
                    block
                    className={
                      proposalData.riskTolerance === "unknown" && "active"
                    }
                    onClick={() =>
                      updateProposalData("riskTolerance", "unknown")
                    }
                  >
                    I don&apos;t know
                  </Button>
                </>
              )}
              {currentStepIndex === 2 && (
                <>
                  <InputContainer labelText={"Investment Amount"}>
                    <InputNumber
                      className="input-custom"
                      min={0}
                      max={100000000}
                      step={1000}
                      value={proposalData.investmentAmount}
                      formatter={getCurrencyValue}
                      parser={cleanCurrencyValue}
                      onChange={value =>
                        updateProposalData("investmentAmount", value)
                      }
                      onClick={selectValue}
                    />
                  </InputContainer>
                  <InputContainer labelText={"Length of Investment"}>
                    <InputNumber
                      className="input-custom"
                      value={proposalData.investmentDuration}
                      min={1}
                      max={80}
                      onChange={value =>
                        updateProposalData("investmentDuration", value)
                      }
                      onClick={selectValue}
                    />
                  </InputContainer>
                  {proposalData.investmentObjective === "income" && (
                    <InputContainer labelText={"Year to Start Withdrawals"}>
                      <InputNumber
                        className="input-custom"
                        value={proposalData.yearToStartWithdrawals}
                        min={1}
                        max={80}
                        onChange={value =>
                          updateProposalData("yearToStartWithdrawals", value)
                        }
                        onClick={selectValue}
                      />
                    </InputContainer>
                  )}
                </>
              )}
            </Space>
          </Space>
          <Space
            direction="vertical"
            align="center"
            style={{ width: "100%" }}
            size={2}
          >
            {currentStepIndex === 2 ? (
              <StyledButton
                size="large"
                type="secondary"
                onClick={() => handleCreateProposal(proposalData)}
                disabled={state.loading}
              >
                Let’s Go
              </StyledButton>
            ) : (
              <StyledButton
                size="large"
                type="secondary"
                onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                disabled={isContinueButtonDisabled()}
              >
                Continue
              </StyledButton>
            )}
            {currentStepIndex === 0 ? (
              <StyledButton size="large" type="text" onClick={handleClose}>
                Cancel
              </StyledButton>
            ) : (
              <StyledButton
                size="large"
                type="text"
                onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
              >
                Back
              </StyledButton>
            )}
            <Row
              align="middle"
              justify="center"
              style={{ gap: 8, marginBottom: 20 }}
            >
              {stepsCopy?.map((elem, index) => (
                <Bullet
                  key={index}
                  size={11}
                  color={currentStepIndex === index ? "#F7733C" : "#D1D1D1"}
                />
              ))}
            </Row>
          </Space>
        </Row>
      </Layout.Content>
      <MediaQuery minWidth={1200}>
        <Row style={{ flexDirection: "column", width: 680 }} wrap={false}>
          <section className="guide-right-section">
            {rightSideImage}
            <SubTitle>{subTitle}</SubTitle>
            <SubDescription>{subDescription}</SubDescription>
          </section>
          {currentStepIndex === 3 && <GuideDisclaimer />}
        </Row>
      </MediaQuery>
    </Row>
  );
};

export default QuestionnaireContentDefault;
