import { useContext } from "react";
import State from "../../../../context";
import ContainerHelpButtons from "../../../../organisms/SectionAskAdvice/components/ContainerHelpButtons";

import StyledHeader from "./styles";

const Header = ({ children, hideNavbar, style }) => {
  const [state] = useContext(State);

  const handleAskButtonClick = activeTabName => {
    state.setKeyValue("openModalAskAdvice", true);
    state.setKeyValue("sectionAskAdviceActiveTab", activeTabName);
  };

  return (
    <StyledHeader style={style}>
      <div>{children}</div>
      {!hideNavbar && (
        <ContainerHelpButtons
          handleButtonClick={handleAskButtonClick}
          isAdvisorButtonActive={
            state.openModalAskAdvice &&
            state.sectionAskAdviceActiveTab === "advisor"
          }
          isAiButtonActive={
            state.openModalAskAdvice &&
            state.sectionAskAdviceActiveTab !== "advisor"
          }
        />
      )}
    </StyledHeader>
  );
};

export default Header;
