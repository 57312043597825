import { useContext, useEffect, useState } from "react";
import State from "../../../../../../context";
import { Button, Flex, Image } from "antd";
import ModalMilestonesNote from "../ModalMilestonesNote";
import TableMilestones from "./components/TableMilestones";

import StylesContainer from "./styles";

import { LIST_MILESTONES_QUESTION, STEPS_CONTENT } from "./constants";

import demo from "./images/demo.png";
import { ReactComponent as ArrowLeft } from "./images/icon_arrow_left.svg";
import { ReactComponent as ArrowRight } from "./images/icon_arrow_right.svg";
import { ReactComponent as IconNote } from "./images/icon_note_small.svg";

const SectionMilestonesQuestionnaire = () => {
  const [state] = useContext(State);
  const [milestonesQuestions, setMilestonesQuestions] = useState(
    LIST_MILESTONES_QUESTION
  );
  const [currentStep, setCurrentStep] = useState("career_marriage");

  useEffect(() => {
    if (state.viewMilestonesQuestionnaire) {
      const milestonesQuestions = state.getPreferenceValue(
        "milestonesQuestions"
      );

      milestonesQuestions && setMilestonesQuestions(milestonesQuestions);
    }
  }, [state.viewMilestonesQuestionnaire]);

  const closeMilestonesView = () => {
    state.setKeyValue("viewMilestonesQuestionnaire", false);
    state.setKeyValue("showButtonBackToDashboard", false);
  };

  const handleAddNoteButton = () => {
    state.setKeyValue("openModalMilestonesNote", true);
  };

  const handleBackButton = () => {
    switch (currentStep) {
      case "career_marriage":
        closeMilestonesView();
        break;
      case "family_health":
        setCurrentStep("career_marriage");
        break;
      case "assets_generosity":
        setCurrentStep("family_health");
    }
  };

  const handleContinueButton = () => {
    switch (currentStep) {
      case "career_marriage":
        setCurrentStep("family_health");
        break;
      case "family_health":
        setCurrentStep("assets_generosity");
        break;
      case "assets_generosity":
        saveMilestonesQuestions();
    }
  };

  const handleCustomNameChange = ({ id, newLabel }) => {
    setMilestonesQuestions(lastState =>
      lastState.map(it => (it.id === id ? { ...it, label: newLabel } : it))
    );
  };

  const handleNoteSave = noteCopy => {
    state.setKeyValue("openModalMilestonesNote", false);
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue("milestonesNote", noteCopy)
      .then(() => state.showSuccess("Note updated"))
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const saveMilestonesQuestions = () => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue("milestonesQuestions", milestonesQuestions)
      .then(() => {
        state.showSuccess("Milestones has been saved.");
        closeMilestonesView();
      })
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const toggleCheckbox = ({ id, newValue }) => {
    setMilestonesQuestions(lastState =>
      lastState.map(it =>
        it.id === id
          ? { ...it, value: it.value === newValue ? null : newValue }
          : it
      )
    );
  };

  return (
    <StylesContainer gap={32} vertical>
      <Flex className={"header"} justify={"space-between"}>
        <Flex gap={8} vertical>
          <h2>Milestones</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            tempus ac nisi nec euismod. Praesent venenatis semper facilisis.
            Praesent in eleifend ligula, sollicitudin elementum est.
          </p>
        </Flex>
        <Image preview={false} src={demo} />
      </Flex>
      <Flex className={"body"} gap={32} vertical>
        <h3>{STEPS_CONTENT[currentStep].title}</h3>
        <Flex gap={40}>
          <TableMilestones
            data={milestonesQuestions.filter(
              it => it.type === STEPS_CONTENT[currentStep].tableData[0]
            )}
            handleCustomNameChange={handleCustomNameChange}
            toggleCheckbox={toggleCheckbox}
          />
          <TableMilestones
            data={milestonesQuestions.filter(
              it => it.type === STEPS_CONTENT[currentStep].tableData[1]
            )}
            handleCustomNameChange={handleCustomNameChange}
            toggleCheckbox={toggleCheckbox}
          />
        </Flex>
      </Flex>
      <Flex className={"footer"} justify={"space-between"}>
        <Button
          icon={<ArrowLeft />}
          onClick={handleBackButton}
          shape={"round"}
          size={"large"}
        >
          Back
        </Button>
        <Flex gap={20}>
          <Button
            icon={<IconNote />}
            onClick={handleAddNoteButton}
            shape={"round"}
            size={"large"}
            style={{ fontSize: 14 }}
          >
            Add note
          </Button>
          <Button
            disabled={state.loading}
            icon={currentStep !== "assets_generosity" && <ArrowRight />}
            onClick={handleContinueButton}
            shape={"round"}
            size={"large"}
            style={{ background: "#428916" }}
            type={"primary"}
          >
            {currentStep === "assets_generosity" ? "Save & Close" : "Continue"}
          </Button>
        </Flex>
      </Flex>

      <ModalMilestonesNote
        loading={state.loading}
        markdown={state.getPreferenceValue("milestonesNote")}
        open={state.openModalMilestonesNote}
        onCancel={() => state.setKeyValue("openModalMilestonesNote", false)}
        onSuccess={handleNoteSave}
      />
    </StylesContainer>
  );
};

export default SectionMilestonesQuestionnaire;
