import { useEffect, useState } from "react";
import { Button, Flex } from "antd";
// documentation https://mdxeditor.dev/editor/docs/customizing-toolbar
import {
  BoldItalicUnderlineToggles,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

import StyledModal from "./styles";

import Close from "../../../../../../icons/Close";
import { ReactComponent as IconNote } from "./images/icon_note_large.svg";

const ModalMilestonesNote = ({
  loading,
  markdown,
  onCancel,
  onSuccess,
  open,
}) => {
  const [editableMarkdown, setEditableMarkdown] = useState(markdown);

  useEffect(() => {
    if (open) {
      setEditableMarkdown(markdown);
    }
  }, [open]);

  const handleButtonSave = () => {
    onSuccess(editableMarkdown);
  };

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} size={"large"}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleButtonSave}
            shape={"round"}
            size={"large"}
            style={{ background: "#428916" }}
            type={"primary"}
          >
            Save note
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={
        <Flex align={"end"} gap={16}>
          <IconNote />
          Add Note
        </Flex>
      }
    >
      <MDXEditor
        className={"markdown-container"}
        markdown={editableMarkdown}
        onChange={setEditableMarkdown}
        plugins={[
          listsPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <UndoRedo />
                <BoldItalicUnderlineToggles />
                <ListsToggle />
              </>
            ),
          }),
        ]}
      />
    </StyledModal>
  );
};

export default ModalMilestonesNote;
