import { useContext } from "react";
import State from "../../../../../../context";
import { Button, Flex, theme } from "antd";
import AnalyticValue from "../../../../../../atoms/AnalyticValue";
import ButtonDemoMode from "../../../../../../atoms/ButtonDemoMode";
import TableLevelClients from "../TableLevelClients";

import { getManagedUsers } from "../../../../../../utils/helpers/specialized";
import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helpers/general";

const SectionClientsList = () => {
  const [state] = useContext(State);
  const { token } = theme.useToken();

  const getTotalAum = () =>
    getManagedUsers(state).length
      ? getManagedUsers(state).reduce(
          (acc, it) =>
            it.valueMap?.productMap
              ? acc +
                Object.keys(it.valueMap.productMap)
                  .map(
                    key =>
                      it.valueMap.productMap[key][
                        it.valueMap.productMap[key].length - 1
                      ].investmentAmount
                  )
                  .reduce((acc, value) => acc + value, 0)
              : acc + (it.proposedAmount ?? 0),
          0
        )
      : 0;

  return (
    <Flex gap={20} vertical>
      <Flex align={"end"} className={"header"} justify={"space-between"}>
        <Flex gap={100}>
          <AnalyticValue
            label={"Total Clients"}
            value={getManagedUsers(state).length}
          />
          <AnalyticValue
            label={"Total Proposed AUM"}
            value={getCurrencyValueWithRoundSign(getTotalAum())}
          />
        </Flex>
        <Flex gap={14}>
          <ButtonDemoMode
            organization={state.organization?.name}
            userManagerEmail={state._id}
          />
          <Button
            type="primary"
            size="small"
            shape="round"
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
            onClick={() => {
              state.setKeyValue("activeEmail", null);
              state.setKeyValue("openModalAddManagedUser", true);
            }}
          >
            + Add New Client
          </Button>
        </Flex>
      </Flex>
      <TableLevelClients />
    </Flex>
  );
};

export default SectionClientsList;
