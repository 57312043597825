import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { Button, Flex, Image } from "antd";

import StyledModal from "./styles";

import { PDF_CONVERTER_OPTIONS } from "../../../../utils/constants";

import { getClonedNode } from "../../../../utils/helpers/specialized";
import { getCurrencyValue } from "../../../../utils/helpers/general";

import Close from "../../../../icons/Close";
import Upload from "../../../../icons/Upload";
import footer from "./images/footer.svg";
import logo from "./images/logo.svg";
import radio_checked from "./images/radio_checked.svg";
import slogan from "./images/slogan.png";

const RadioButton = ({ checked }) =>
  checked ? (
    <Image src={radio_checked} style={{ minWidth: 18 }} />
  ) : (
    <div
      style={{
        border: "2px solid #8396A6",
        borderRadius: "50%",
        boxSizing: "border-box",
        height: 18,
        minWidth: 18,
        width: 18,
      }}
    />
  );

const ModalViewIpq = ({ activeUserData, onCancel, open }) => {
  const prospectObjective = activeUserData?.valueMap?.prospectObjective;
  const guideInitialData = activeUserData?.valueMap?.guideInitialData;

  const handleDownload = () => {
    const page1 = getClonedNode(document.getElementById("page1"));
    const page2 = getClonedNode(document.getElementById("page2"));

    html2pdf()
      .set(PDF_CONVERTER_OPTIONS)
      .from(page1)
      .toContainer()
      .toCanvas()
      .toPdf()
      .get("pdf")
      .then(pdf => pdf.addPage())
      .set(PDF_CONVERTER_OPTIONS)
      .from(page2)
      .toContainer()
      .toCanvas()
      .toPdf()
      .output("bloburl")
      .then(function (pdf) {
        let pdfWindow = window.open("", "_blank");
        pdfWindow.document.write(
          "<iframe  width='100%' height='100%' src='" + pdf + "'></iframe>"
        );
        pdfWindow.document.title = "OneAscent IPQ PDF";
      });
  };

  const Header = () => (
    <header
      style={{
        alignItems: "end",
        borderBottom: "1px solid #bec8ce",
        display: "flex",
        fontSize: 10,
        justifyContent: "space-between",
        lineHeight: "14px",
        paddingBottom: 9,
      }}
    >
      <Image preview={false} src={logo} width={136} />
      <div
        style={{ alignItems: "end", display: "flex", flexDirection: "column" }}
      >
        <b>Personalized Investment Proposal</b>
        <span style={{ textTransform: "capitalize" }}>
          {activeUserData?.firstName} {activeUserData?.lastName}
          {guideInitialData?.isMarried
            ? " & " +
              guideInitialData.spouseFirstName +
              " " +
              guideInitialData.spouseLastName
            : ""}
        </span>
        <span>
          {dayjs(
            guideInitialData?.startDate ??
              prospectObjective?.proposalSavedDate?.$date ??
              activeUserData?.updateDateTime?.$date
          ).format("MM/DD/YYYY")}
        </span>
      </div>
    </header>
  );

  const Page1 = () => (
    <div
      className={"page-container"}
      style={{
        color: "#2A2828",
        fontFamily: "Helvetica, sans-serif",
        height: 1054,
        position: "relative",
      }}
      id="page1"
    >
      <div
        style={{
          boxSizing: "border-box",
          fontSize: 12,
          lineHeight: "16px",
          padding: "42px 38px",
        }}
      >
        <Header />
        <div
          style={{
            borderBottom: "1px solid #BEC8CE",
            color: "#234744",
            fontSize: 16,
            fontWeight: 300,
            margin: "24px 0 16px",
            paddingBottom: 4,
          }}
        >
          About You
        </div>
        <div style={{ display: "flex", gap: 33 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 7,
              width: 325,
            }}
          >
            <b style={{ color: "#2A2828", marginBottom: 9 }}>Current Age</b>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "-45"}
              />
              <span>Under 45</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "45-55"}
              />
              <span>45 - 55</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "56-65"}
              />
              <span>56 - 65</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "66-75"}
              />
              <span>66 - 75</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "75-"}
              />
              <span>Over 75</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <b style={{ color: "#2A2828", marginBottom: 9 }}>
              Your expectations of income changes over the next several years.
            </b>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I fear I might lose my job or I plan to retire"
                }
              />
              <span>I fear I might lose my job or I plan to retire.</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I anticipate my income will probably trend downward"
                }
              />
              <span>I anticipate my income will probably trend downward.</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I expect a fairly level income"
                }
              />
              <span>I expect a fairly level income.</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I anticipate a steadily growing income"
                }
              />
              <span>I anticipate a steadily growing income.</span>
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #BEC8CE",
            color: "#234744",
            fontSize: 16,
            fontWeight: 300,
            margin: "24px 0 16px",
            paddingBottom: 4,
          }}
        >
          Time Horizon and Investment Experience
        </div>
        <div style={{ display: "flex", gap: 33 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 7,
              width: 325,
            }}
          >
            <b style={{ color: "#2A2828", marginBottom: 9 }}>
              When do you plan to begin withdrawal of at least 5% of your
              investments annually?
            </b>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "-3"}
              />
              <span>0 - 3 years</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "3-5"}
              />
              <span>3 - 5 years</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "5-10"}
              />
              <span>5 - 10 years</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "10-"}
              />
              <span>More than 10 years</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 7,
              width: 385,
            }}
          >
            <b style={{ color: "#2A2828", marginBottom: 9 }}>
              Which Statement best describes your knowledge of investments?
            </b>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "I know virtually nothing about investing"
                }
              />
              <span>I know virtually nothing about investing.</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "My understanding of the markets and investing is minimal"
                }
              />
              <span>
                My understanding of the markets and investing is minimal.
              </span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "Somewhat knowledgeable. I have a basic comprehension of investing"
                }
              />
              <span>
                Somewhat knowledgable. I have a basic comprehension of
                investing.
              </span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "Highly knowledgeable. I have a good understanding of how the stock and bond markets work, and I spend considerable time keeping up with financial matters"
                }
              />
              <span>
                Highly knowledgable. I have a good understanding of how the
                stock and bond markets work, and I spend considerable time
                keeping up with financial matters.
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #BEC8CE",
            color: "#234744",
            fontSize: 16,
            fontWeight: 300,
            margin: "3px 0 16px",
            paddingBottom: 4,
          }}
        >
          Returns, Risk, Volatility...
        </div>
        <div style={{ display: "flex", gap: 33 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 7,
              width: 325,
            }}
          >
            <b style={{ color: "#2A2828", marginBottom: 9 }}>
              An investment decision involves both returns and risk - the higher
              the potential for returns, the greater the risk of highly volatile
              results, including loss. What influences you the most when making
              an important investment decision?
            </b>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.decisionInfluence ===
                  "I am mostly influenced by the potential loss"
                }
              />
              <span>I am mostly influenced by the potential loss.</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.decisionInfluence ===
                  "I am equally influenced by potential loss or gain"
                }
              />
              <span>I am equally influenced by potential loss or gain.</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.decisionInfluence ===
                  "I am mostly influenced by the potential gain"
                }
              />
              <span>I am mostly influenced by the potential gain.</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 7,
              width: 385,
            }}
          >
            <b style={{ color: "#2A2828", marginBottom: 9 }}>
              Understanding that volatility has historically been present when
              investing, what is the maximum amount of volatility you can accept
              over a 1 year period and remain invested?
            </b>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Any decrease in value would concern me"
                }
              />
              <span>Any decrease in value would concern me.</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Up to 10% downside ($100,000 falling to $90,000)"
                }
              />
              <span>Up to 10% downside ($100,000 falling to $90,000).</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Up to 20% downside ($100,000 falling to $80,000)"
                }
              />
              <span>Up to 20% downside ($100,000 falling to $80,000).</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Up to 30% downside ($100,000 falling to $70,000)"
                }
              />
              <span>Up to 30% downside ($100,000 falling to $70,000).</span>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "I would not worry about performance for that short of a period"
                }
              />
              <span>
                I would not worry about performance for that short of a period.
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 7,
            width: 325,
          }}
        >
          <b style={{ color: "#2A2828", marginBottom: 9 }}>
            When the next downturn in the market comes, how long are you
            prepared to ride out the declining market?
          </b>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.decliningMarketWithholdingPeriod ===
                "Less than 1 year"
              }
            />
            <span>Less than 1 year</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.decliningMarketWithholdingPeriod ===
                "Between 1-5 years"
              }
            />
            <span>Between 1-5 years</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.decliningMarketWithholdingPeriod ===
                "More than 5 years"
              }
            />
            <span>More than 5 years</span>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #BEC8CE",
            color: "#234744",
            fontSize: 16,
            fontWeight: 300,
            margin: "24px 0 7px",
            paddingBottom: 4,
          }}
        >
          A bit about you…
        </div>
        <div style={{ display: "flex", gap: 33 }}>
          <div
            style={{
              display: "flex",
              gap: 100,
              width: 325,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
              <span>Amount</span>
              <b>
                {getCurrencyValue(prospectObjective?.investmentAmount) || "N/A"}
              </b>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
              <span>Time</span>
              <b>
                {prospectObjective?.investmentDuration
                  ? `${prospectObjective?.investmentDuration} Years`
                  : "N/A"}
              </b>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 40,
              width: 385,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
              <span>Annual Investment Contributions</span>
              <b>
                {getCurrencyValue(prospectObjective?.contributions) || "N/A"}
              </b>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
              <span>Combined Household Income</span>
              <b>
                {getCurrencyValue(prospectObjective?.householdIncome) || "N/A"}
              </b>
            </div>
          </div>
        </div>
      </div>
      <footer style={{ bottom: 0, position: "absolute" }}>
        <Image preview={false} src={footer} width={"100%"} />
      </footer>
    </div>
  );

  const Page2 = () => (
    <div
      className={"page-container"}
      style={{
        color: "#2A2828",
        fontFamily: "Helvetica, sans-serif",
        height: 1054,
        position: "relative",
      }}
      id="page2"
    >
      <div
        style={{
          boxSizing: "border-box",
          padding: "42px 38px",
        }}
      >
        <Header />
        <div style={{ color: "#1A3A63", fontSize: 20, marginTop: 25 }}>
          Risk Selection
        </div>
        <div
          style={{
            fontSize: 12,
            lineHeight: "16px",
            marginTop: 20,
            paddingLeft: 20,
          }}
        >
          <div>
            <b>A:</b> Your calculated risk score is{" "}
            <b style={{ textTransform: "capitalize" }}>
              {prospectObjective?.calculatedRiskTolerance ?? "--"}
            </b>
          </div>
          <div style={{ marginTop: 12 }}>
            <b>B:</b> You selected an investment with a goal of{" "}
            <b style={{ textTransform: "capitalize" }}>
              {prospectObjective?.riskTolerance ?? "--"}
            </b>
          </div>
          <div
            style={{
              alignItems: "center",
              gap: 16,
              display: "flex",
              marginTop: 16,
            }}
          >
            <RadioButton
              checked={prospectObjective?.acceptedRiskLevel === "recommended"}
            />
            <span>
              All accounts should be invested according to my measured risk
              level listed above <b>(A)</b>
            </span>
          </div>
          <div style={{ gap: 16, display: "flex", marginTop: 20 }}>
            <RadioButton
              checked={prospectObjective?.acceptedRiskLevel === "custom"}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                I wish to deviate from my measured risk level and request that
                my account be invested
                <br /> according to my selection above <b>(B)*</b>
              </span>
              {prospectObjective?.acceptedRiskLevel === "custom" && (
                <table style={{ borderSpacing: 0, marginTop: 12, width: 380 }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: "1px solid #70707050",
                          textAlign: "left",
                          width: 240,
                        }}
                      >
                        Account
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #70707050",
                          textAlign: "left",
                          width: 140,
                        }}
                      >
                        Risk Tolerance
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          maxWidth: 240,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prospectObjective?.listAccounts?.account__name__1}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {prospectObjective?.listAccounts?.account__riskType__1}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          maxWidth: 240,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prospectObjective?.listAccounts?.account__name__2}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {prospectObjective?.listAccounts?.account__riskType__2}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          maxWidth: 240,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prospectObjective?.listAccounts?.account__name__3}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {prospectObjective?.listAccounts?.account__riskType__3}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          maxWidth: 240,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prospectObjective?.listAccounts?.account__name__4}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {prospectObjective?.listAccounts?.account__riskType__4}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          maxWidth: 240,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prospectObjective?.listAccounts?.account__name__5}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {prospectObjective?.listAccounts?.account__riskType__5}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <p style={{ margin: "54px 0 66px", paddingRight: 70 }}>
            <b>* Misaligned Portfolio Consent:</b> In the event that the
            portfolio selected above does not correspond to the measured risk
            tolerance information above, I understand that I have chosen a
            different portfolio despite the lack of alignment with risk
            tolerance and am comfortable with this choice and the risks
            associated with such choice, including the risk that I may lose
            principal. I further understand that it is my responsibility to
            communicate any changes in my circumstances, risk tolerance, or
            portfolio selection to my adviser. In addition, I will regularly
            review all account statements and reports from my adviser and alert
            my adviser if I have any questions, comments or concerns.
          </p>
          <div style={{ display: "flex", gap: 6 }}>
            <div
              style={{
                alignItems: "end",
                display: "flex",
                gap: 23,
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Client Name:</div>
                <div
                  style={{ borderBottom: "1px solid #707070", width: 230 }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>Client Signature:</div>
                <div
                  style={{ borderBottom: "1px solid #707070", width: 230 }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>Date:</div>
                <div
                  style={{ borderBottom: "1px solid #707070", width: 230 }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>Advisor Signature:</div>
                <div
                  style={{ borderBottom: "1px solid #707070", width: 230 }}
                />
              </div>
            </div>
            <div
              style={{
                alignItems: "end",
                display: "flex",
                gap: 23,
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Secondary Client Name:</div>
                <div
                  style={{ borderBottom: "1px solid #707070", width: 250 }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>Secondary Signature:</div>
                <div
                  style={{ borderBottom: "1px solid #707070", width: 250 }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>Date:</div>
                <div
                  style={{ borderBottom: "1px solid #707070", width: 250 }}
                />
              </div>
            </div>
          </div>
          <p style={{ marginTop: 52 }}>
            <b>Notes:</b>
          </p>
          <p style={{ paddingRight: 70 }}>{prospectObjective?.note}</p>
        </div>
      </div>
      <footer style={{ bottom: 0, position: "absolute" }}>
        <Image
          preview={false}
          src={slogan}
          style={{ margin: "0 0 17px 38px" }}
          width={183}
        />
        <Image preview={false} src={footer} width={"100%"} />
      </footer>
    </div>
  );

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"} wrap={"wrap"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Close
          </Button>
          <Flex gap={12}>
            <Button disabled shape={"round"} type={"custom"}>
              Send for signature
            </Button>
            <Button
              icon={<Upload />}
              onClick={handleDownload}
              shape={"round"}
              type={"custom"}
            >
              Download IPQ
            </Button>
          </Flex>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={"IPQ"}
      width={900}
    >
      <Flex gap={32} vertical>
        <Page1 />
        <Page2 />
      </Flex>
    </StyledModal>
  );
};

export default ModalViewIpq;
