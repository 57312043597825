import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../context";
import { Flex, Layout, theme } from "antd";
import MenuManagerProfile from "../../../molecules/MenuManagerProfile";
import ModalAdminSettings from "../../../organisms/ModalAdminSettings";
import SectionAskAdvice from "../../../organisms/SectionAskAdvice";
import ModalConversationDetails from "../components/ModalConversationDetails";

import { ChildIcon, StyledMenu } from "./styles";

import CleverLogo from "../images/CleverLogo";
import EnterprisesIcon from "./images/EnterprisesIcon";
import EnvironmentsIcon from "./images/EnvironmentsIcon";
import PennyIcon from "./images/PennyIcon";
import RolesPermissionsIcon from "./images/RolesPermissionsIcon";
import UserActivityIcon from "./images/UserActivityIcon";
import UsersIcon from "./images/UsersIcon";

const { Content, Sider } = Layout;

const menuItems = [
  {
    icon: <EnterprisesIcon />,
    key: "/super-admin/enterprises",
    label: "Enterprises",
  },
  {
    children: [
      {
        icon: <ChildIcon />,
        key: "/super-admin/next-advisors",
        label: "Next Advisors",
      },
      {
        icon: <ChildIcon />,
        key: "/super-admin/next-leads",
        label: "Next Leads",
      },
    ],
    key: "users",
    label: (
      <Flex align={"center"} gap={8}>
        <UsersIcon />
        All Users
      </Flex>
    ),
  },
  {
    icon: <UserActivityIcon />,
    key: "/super-admin/user-activity",
    label: "User Activity",
  },
  {
    icon: <RolesPermissionsIcon />,
    key: "/super-admin/roles-permissions",
    label: "Roles & Permissions",
  },
  {
    children: [
      {
        icon: <ChildIcon />,
        key: "/super-admin/conversations",
        label: "Conversations",
      },
      {
        icon: <ChildIcon />,
        key: "/super-admin/training-lab",
        label: "Training Lab",
      },
    ],
    key: "penny",
    label: (
      <Flex align={"center"} gap={8}>
        <PennyIcon />
        Penny
      </Flex>
    ),
  },
  {
    icon: <EnvironmentsIcon />,
    key: "/super-admin/environments",
    label: "Environments",
  },
];

const SuperAdminLayout = ({ children }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [state, setState] = useContext(State);

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        style={{ background: token.sidebarBackground }}
      >
        <Flex
          justify={"space-between"}
          style={{ height: "100%", padding: "37px 23px" }}
          vertical
        >
          <Flex gap={36} vertical>
            <div style={{ paddingLeft: 24 }}>
              <CleverLogo />
            </div>
            <StyledMenu
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              items={menuItems}
              onClick={e => navigate(e.key)}
            />
          </Flex>
          <div style={{ paddingLeft: 24 }}>
            <MenuManagerProfile logoutLink={"/login-super-admin"} />
          </div>
        </Flex>
      </Sider>
      <Layout>
        <Content
          style={{
            background: "#FFFFFF",
            overflow: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>

      <ModalAdminSettings />
      <SectionAskAdvice state={state} setState={setState} />
      <ModalConversationDetails
        onCancel={() => state.closeModal("conversationModal")}
        open={state.conversationModal}
      />
    </Layout>
  );
};

export default SuperAdminLayout;
